import {actions} from "./actionTypes";

export const UserCabinetInitialState = {
    usersInfo: null,
};



export const UserCabinetReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_USERS_INFO:
            return {
                ...state,
                usersInfo: {...action.payload}
            };
        case actions.EDIT_USERS_INFO:
            return {
                ...state,
                usersInfo:  {...action.payload}
            };
        case actions.REMOVE_USERS_INFO:
            return {
                ...state,
                usersInfo: null
            };
        default:
            return state;
    }
};