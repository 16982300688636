import {PlusOutlined} from '@ant-design/icons';
import {Button, ConfigProvider, Divider, Input, notification, Select, Space} from 'antd';
import {useEffect, useState} from 'react';
import st from './AdministrationSelect.module.css'


const AdministrationSelect = ({changeUsersField, items, ...props}) => {
    const [selectValue, setSelectValue] = useState([])

    useEffect(() => {
        changeUsersField(selectValue)
    }, [selectValue])

    const handleSelectValue = (e) => {
        setSelectValue(e)
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimaryBg: '#ff8400',
                },
            }}
        >
            <Select
                size={'large'}
                mode="multiple"

                value={selectValue}
                filterOption={true}
                optionFilterProp="label"
                placeholder="Виберіть користувачів"
                onChange={handleSelectValue}
                options={items.map((item) => ({
                    label: item.name,
                    value: item._id,
                    className: st.select
                }))}
            />
        </ConfigProvider>
    );
};
export default AdministrationSelect;