import React from "react";
import {Typography, Collapse, Image, Divider, notification} from "antd";
import st from "./ShowQuestionCard.module.css";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import GameSettingContext from "../../../context/GameSettingContext";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


const {Panel} = Collapse;

function ShowQuestionCard({item, setEdit, levelName, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {loading, error, request, clearError} = useHttp();
    const {fillQuestionList} = React.useContext(GameSettingContext);
    const deleteQuestion = async () => {
        try {
            const del = await request(
                `${baseUrl}/api/qa/${item._id}`,
                "DELETE",
                null,
                {authorization: `Bearer ${token?.accessToken}`}
            );
            if (del) {
                const data = await request(
                    `${baseUrl}/api/qa/${del.lvl_id}`,
                    "GET",
                    null,
                    {authorization: `Bearer ${token?.accessToken}`}
                );
                fillQuestionList(data.questions);
                clearError();
            }
            clearError();
        } catch (e) {
            notification["error"]({
                message: e.message,
            });
        }
    };


    return (
        <>
            <div className={st.cardInfoBlock}>
                <div className={st.cardInfo}>
                    <div>
                        <Typography.Title>{`Рівень: ${levelName}`}</Typography.Title>
                        <Typography.Title>{`Номер кроку: ${
                            item.number + 1
                        }`}</Typography.Title>

                    </div>
                    <div className={st.actions}>
                        <Typography.Title className={st.edit} onClick={() => setEdit(item)}>
                            Редагувати крок <EditOutlined/>
                        </Typography.Title>
                        <Typography.Title className={st.delete} onClick={deleteQuestion}>
                            Видалити крок <DeleteOutlined/>
                        </Typography.Title>
                    </div>
                </div>
                <div> {
                    item.question_images.length > 0 && item.question_images.map(el => <Image
                        key={el}
                        width={272}
                        alt="image"
                        src={`${baseUrl}${el}`}
                    />)}</div>
            </div>
            <Divider plain className={st.btn_text}>Питання</Divider>
            <div className={st.textQustion}>
                <p dangerouslySetInnerHTML={{__html: item.question_text}}/>
            </div>
            {item?.answers?.length > 0 && <Divider plain className={st.btn_text}>Відповіді</Divider>}
            {item?.answers?.length > 0 && <Collapse className={st.collapse} defaultActiveKey={"0"}>
                {item.answers?.map((el, key) => (
                    <Panel
                        header={`Відповідь ${key + 1}`}
                        key={key}
                        extra={`${el.points} Балів`}
                    >

                        {el.text ? <p dangerouslySetInnerHTML={{__html: el.text}}/>
                            : <p>Текст Відсутній</p>}

                        <Collapse defaultActiveKey="1">
                            {el.comment && <Panel header={`Коментарій до відповіді`} key="1">

                                <p dangerouslySetInnerHTML={{__html: el.comment}}/>
                            </Panel>}
                            {el.answer_images?.length > 0 && <Panel header={`Зображення`} key="2">
                                <div className={st.answersPicture}>
                                    {el.answer_images.map(el => <Image
                                        key={el}
                                        width={272}
                                        alt="image"
                                        src={`${baseUrl}${el}`}
                                    />)}
                                </div>
                            </Panel>}
                        </Collapse>
                    </Panel>
                ))}
            </Collapse>}

            {item.question_comment?.length > 0 && item.question_comment.map((el, key) =>
                <div key={key}>
                    <Divider plain className={st.btn_text}>Коментар {key + 1}</Divider>
                    <div className={st.mainComment}>
                        <p dangerouslySetInnerHTML={{__html: el}}/>
                    </div>
                </div>)}

            {item.btn_text && <Divider className={st.btn_text} plain>{item.btn_text}</Divider>}
        </>
    );
}


export default ShowQuestionCard;
