import React, {useCallback, useContext, useEffect, useState} from "react"
import {Button, Card, Col, FloatButton, Layout, List, notification, Row, Space, Typography} from "antd";
import st from "../../../page/LandingPage/LandingPage.module.css"

import {NavLink, useNavigate} from "react-router-dom";
import clsx from "clsx";
import GameSettingContext from "../../../context/GameSettingContext";


function LandingHeader(props) {
    const [burger, setBurger] = useState(false)
    const navigate = useNavigate()

    const {isUser} = React.useContext(GameSettingContext);



    return (
        <header className={st.header}>
            <NavLink to={"/"} className={clsx(st.hLogo, st.pOp5h, st.lNo)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 54" enableBackground="new 0 0 172 54">
                    <path
                        d="m159.48 27.675c-3.731-.122-6.727.041-10.681.924 5.131-3.196 7.655-5.678 9.137-8.427 2.999-5.56 1.374-11.02-.642-13.948-2.6-3.782-7.504-5.678-14.468-5.678-7.823 0-20.721 2.313-29.483 9.295l4.04 3.346c-3.084 1.914-8.509 3.829-11.692 3.91l-.083 1.19c-.636 11.283.007 17.271.657 19.521-1.043.092-3.382.133-3.895-2.268-.72-3.37-1.181-11.674.41-21.14-3.13 1.833-9.533 3.591-13.247 3.604l-.171 1.089c-.02.181-.038.362-.057.544-2.053-2.217-4.777-3.546-7.689-3.951l6.269-14.326-2.391-.116c-6.744 0-12.429 2.463-16.548 6.889-2.656 2.854-4.573 6.424-5.7 10.612-2.759-3.501-6.83-5.522-11.442-5.522-4.611 0-8.316 2.026-10.913 5.222.008-.14.013-.28.014-.417.04-2.996-1.574-10.02-17.402-10.584-.753-.027-1.534-.04-2.322-.04-6.784 0-14.12.985-19.16 2.571l-2.023.637 9.824 12.97c0 0 1.789-.331 2.047-.384l.067 3.632c-.033-.001-1.745-.021-1.745-.021l.042 6.443-4.926-2.092.328 10.672c5.733 2.914 10.44 7.621 21.246 7.621 8.181 0 12.166-2.844 13.968-6.227 2.576 2.92 6.275 4.804 10.941 4.804 5.173 0 10.115-3.037 12.883-8.161 2.759 4.962 7.474 8.161 12.963 8.161 5.735 0 10.316-3.088 12.936-7.88 1.83 4.256 6.25 7.196 12.549 7.196 3.419 0 6.11-.451 8.573-1.518 1.235.809 2.7 1.376 4.224 1.709l3.642 5.918c15.02-8 37.17-8 52.44-5.255l-12.518-20.523m-134.39 9.89l-.072-2.9c1.591.198 2.729.579 3.536 1.388-.184.892-1.262 1.635-3.464 1.512m28.09-.062c-1.996-.231-3.391-2.293-3.391-4.963 0-2.476 1.77-4.324 3.569-4.324 2.396 0 3.724 2.039 3.813 3.917.165 3.479-1.888 5.613-3.991 5.37m23.23-1.945c-1.892 0-3.552-2.149-3.552-4.759 0-3.606 1.968-5.182 3.727-5.182 2.345 0 3.727 2.175 3.727 4.833-.001 3.041-1.642 5.108-3.902 5.108m42.45.125c-.468-6.304-.772-14.789-.409-20.2l.097-1.331 14.07 11.661c-4.578 2.887-9.52 6.346-13.753 9.871"
                        fill="#180828"/>
                    <path
                        d="m158.57 29.31c-7.413 0-17.494 2.076-23.425 4.151 4.893-4 14.945-7.864 19.866-13.343 3.16-3.518 3.139-9.411.741-12.898-6.523-9.488-30.772-3.262-39.519 2.816l14.465 11.861c2.52-1.334 5.03-2.899 8.889-3.156 4.448-.297 5.05 2.563 1.786 4.342-6.317 3.445-15.465 9.237-22.31 15.14.277 2.985 1.795 6.289 4.844 8.145.357.217.453.403-.179.675-1.468.63-3.29.908-5.164.834l1.164 1.891c16.308-8.01 36.17-6.82 48.03-5.337l-9.193-15.12"
                        fill="#ff8400"/>
                    <path
                        d="m120.91 46.19c-4.261.211-8.2-1.802-9.322-3.084-5.714 2.942-18.14 2.674-19.86-5.523.824-2.27 1.271-4.805 1.271-7.502 0-3.247-.745-5.965-1.998-8.138.058-.895.133-1.78.227-2.643 2.353-.164 6.374-.983 8.987-2.157-.985 4.898-.841 15.33.098 18.631.848 2.983 4.214 4.378 8.237 3.065-1.519-3.571-1.56-11.698-1.067-20.441 2.627-.493 6.075-1.314 9.09-3.037-.441 6.568-.113 16.852.624 23.06.399 3.359 1.636 6.076 3.79 7.59.077.052.028.17-.075.175m-29.835-16.562c0 9.588-6.309 15.831-13.688 15.831-5.223 0-9.297-3.675-11.433-8.582.675-2.063 1.039-4.361.999-6.86-.054-3.428-.849-6.459-2.2-8.97 2.069-11.696 10.198-17.947 20.801-17.923l-3.941 8.814c-3.282.055-8.7 2.271-9.912 7.58 4.972-5.373 19.374-3.831 19.374 10.11m-8.886-.058c0-4.39-2.501-6.891-5.768-6.891-3.522 0-5.615 3.369-5.615 7.248 0 3.573 2.348 6.891 5.513 6.891 3.164 0 5.87-2.858 5.87-7.248m-17.11.058c0 9.588-6.309 15.831-13.688 15.831-3.869 0-7.212-1.994-9.52-5.01.223-1 .311-2 .311-2.94 0-3.099-1.56-5.833-3.629-7.955.162-4.145 1.502-7.966 3.834-10.71l4.775 2.933-1.509-5.665c1.72-.979 3.722-1.533 5.975-1.533 7.619.001 13.451 5.464 13.451 15.05m-6.02 2.508c0-4.39-2.501-6.891-5.768-6.891-3.522 0-5.615 3.369-5.615 7.248 0 3.573 2.348 6.891 5.513 6.891 3.164.001 5.87-2.858 5.87-7.248m-18.987 5.186c0 5.135-4.279 8.681-12.961 8.742-8.681.061-13.633-3.485-20.17-7.336l-.245-5.441c1.762.807 4.054 1.791 6.596 2.713l-.082-5.802c-.704.062-1.409.134-2.113.216v-2.812c.686.026 1.381.05 2.077.07l-.091-6.454c-.971.156-1.98.347-3.03.576l-7.457-10.03c5.624-1.773 13.939-2.69 20.847-2.445 11.133.393 15.65 4.157 15.589 8.68-.046 3.423-2.14 7.336-7.153 9.231 3.301 1.407 8.192 4.953 8.192 10.09m-17.05-16.343l.236 6.418c2.926-.39 4.89-1.181 4.833-2.669-.071-1.848-1.89-3.183-5.069-3.749m.34 9.246l.311 8.463c3.17.316 6.328-.164 6.558-3.383.213-2.994-2.663-4.505-6.869-5.08"
                        fill="#eceff1"/>
                </svg>
            </NavLink>
            <div className={clsx(st.hMenuMobile, st.pOp5h)} onClick={()=>setBurger(!burger)}>
                <div className={st.one}></div>
                <div className={st.two}></div>
                <div className={st.three}></div>
            </div>
            <ul id="h-menu" className={clsx(st.hMenu,
                burger && st.hMenuActive
                )}>
                <li><NavLink to={"/about"}>Про гру</NavLink></li>
                <li><NavLink to={"/rewards"}>Нагороди</NavLink></li>
                <li><NavLink to={"/rating"}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" enableBackground="new 0 0 16 14" className={st.iRating}><g fill="#ffffff" id="i-rating"><path d="m9.575 14h-3.15c-.235 0-.425-.19-.425-.425v-13.15c0-.235.19-.425.425-.425h3.15c.235 0 .425.19.425.425v13.15c0 .235-.19.425-.425.425" /><path d="m15.575 14h-3.15c-.235 0-.425-.19-.425-.425v-6.15c0-.235.19-.425.425-.425h3.15c.235 0 .425.19.425.425v6.15c0 .235-.19.425-.425.425" /><path d="M3.575,14h-3.15C0.19,14,0,13.81,0,13.575v-8.15C0,5.19,0.19,5,0.425,5h3.15C3.81,5,4,5.19,4,5.425v8.15
                        C4,13.81,3.81,14,3.575,14z" /></g></svg>Рейтинг</NavLink></li>
                <li><NavLink to={"/directory"}>Довідник</NavLink></li>
                <li>
                    <button onClick={()=>document.location='https://t.me/voby2_bot'}>Почати гру</button>
                </li>
                { isUser ?<li>
                        <button onClick={()=>navigate("/cabinet")}>Кабінет</button>
                    </li>:
                <li>
                    <NavLink to={"/auth"} className="md-modal-open l-no btn-login" title="Увійти в акаунт"><span
                        className="login-text">Вхід</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enableBackground="new 0 0 24 24"
                             className={st.iLogin}>
                            <path id="i-login"
                                  d="m18 17.6c-.331 0-.6-.269-.6-.6v-10c0-.332.269-.6.6-.6s.6.269.6.6v10c0 .331-.269.6-.6.6m-2.531-5.975l-4-5c-.209-.26-.587-.3-.844-.094-.259.207-.301.585-.094.844l3.22 4.03h-9.751c-.332 0-.6.269-.6.6s.269.6.6.6h9.752l-3.22 4.03c-.207.259-.165.637.094.844.109.088.242.131.374.131.176 0 .35-.077.469-.225l4-5c.175-.22.175-.53 0-.75m-3.469-10.425c5.955 0 10.8 4.845 10.8 10.8s-4.845 10.8-10.8 10.8-10.8-4.845-10.8-10.8 4.845-10.8 10.8-10.8m0-1.2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
                                  fill="#ffffff"/>
                        </svg>
                    </NavLink>
                </li>}
            </ul>
        </header>
    );
}


export default LandingHeader;
