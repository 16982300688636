import React from "react"
import {
    Form,
    Modal,
    notification, Slider, Switch,
} from "antd";
import st from './EditRanksModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";



function EditRanksModal({isModalOpen, setIsModalOpen, lvl, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {request, clearError} = useHttp()
    const {fillRanksList, ranksList} = React.useContext(GameSettingContext);

    const newMinRange = ranksList[isModalOpen.bottomRank] ? ranksList[isModalOpen.bottomRank].min + 2 : 0
    const newMaxRange = ranksList[isModalOpen.topRank] ? ranksList[isModalOpen.topRank].max - 2 : newMinRange + 300

    const uploadImage = (body) => {
        form.setFieldsValue({images: body})
    }

    const uploadAvatarImage = (body) => {
        if(body.length > 0){
            form.setFieldsValue( { avatar_image: body[0]})
        }else {
            form.setFieldsValue( { avatar_image: null})
        }
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen({});
    };


    const onFinish = async (values) => {
        let bottomRank = ranksList[isModalOpen.bottomRank] ? ranksList[isModalOpen.bottomRank]._id : null
        let topRank = ranksList[isModalOpen.topRank] ? ranksList[isModalOpen.topRank]._id : null
        try {
            const body = JSON.stringify({
                min: values.range[0],
                max: values.range[1],
                images: values.images,
                avatar_image: values.avatar_image,
                reserved: values.reserved,
                bottomRank, topRank
            })
            const data = await request(`${baseUrl}/api/ranks/${isModalOpen._id}`, "PUT", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            fillRanksList(data)
            clearError()
            setIsModalOpen({})

        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };



    return (

        <Modal title={`Редагування рангу`}
               okText="Редагувати"
               cancelText="Скасувати" open={Object.keys(isModalOpen).length > 0} onOk={handleOk} onCancel={handleCancel}
               width={500} centered>

            <Form className={st.card} form={form} onFinish={onFinish}
                  autoComplete="off" initialValues={{reserved: isModalOpen.reserved ,range: [isModalOpen.min, isModalOpen.max]}}>
                <Form.Item label="Деактивувати із збереженням діапазону" name="reserved"  valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item name="range" rules={[
                    {
                        required: true,
                        message: 'Відсутній діапозон',
                    },
                ]}>
                    <Slider
                        range={true}
                        min={newMinRange}
                        max={newMaxRange}
                    />
                </Form.Item>
                <Form.Item name={"images"}>
                    <UploadAvatar
                        placeholder={"Карткочка рангу"}
                        multiple={false}
                        setImageFile={uploadImage}
                        totalCount={1}
                        qaFileList={isModalOpen.images}
                    />
                </Form.Item>
                <Form.Item name={"avatar_image"}>
                    <UploadAvatar
                        placeholder={"Аватарка рангу"}
                        multiple={false}
                        setImageFile={uploadAvatarImage}
                        totalCount={1}
                        qaFileList={isModalOpen.avatar_image ? [isModalOpen.avatar_image] : []}
                    />
                </Form.Item>
            </Form>
        </Modal>

    );
}


export default EditRanksModal;
