import {Button, Result, Spin} from 'antd';
import React from 'react';
import st from "./DefaultPage.module.css"
import {useDocumentTitle} from "../../hooks/title.hook";
import {useNavigate} from "react-router-dom";

const DefaultPage = () => {
    const navigate = useNavigate()
    const [document_title, setDoucmentTitle] = useDocumentTitle("Сторінка Помилки — Воби2");
    return (
        <div className={st.main}>
            <Result
                status="500"
                title="404"
                subTitle="Вибачте, щось пішло не так."
                extra={<Button onClick={()=>navigate('/')} type="primary">На Головну</Button>}
            />
        </div>
    )
}

export default DefaultPage