import React, {useState} from "react";
import {
    Button, Drawer, Form, notification, Steps, theme, message, Typography,
} from "antd";
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import FormStep from "./steps/FormStep/FormStep";
import SelectFieldsStep from "./steps/SelectFieldsStep/SelectFieldsStep";
import st from './createQuestionModal.module.css'

function CreateQuestionModal({
                                 isModalOpen,
                                 setIsModalOpen,
                                 lvl,
                                 num,
                                 ...props
                             }) {


    const [form] = Form.useForm();
//checkbox directorSelect fields
    const defaultCheckedList = ['question'];
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const plainOptions = [{label: 'Запитання', value: "question"}, {
        label: 'Картинка',
        value: "picture"
    }, {label: 'Коментар', value: "text"}, {
        label: 'Змінити текст кнопки',
        value: "button",
        disabled: checkedList.length < 1
    }];
//checkbox directorSelect fields


    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false);
    };

    return (
        <Drawer
            title="Створення нового кроку"
            open={isModalOpen}
            onClose={handleCancel}
            size={'large'}
        >
            <div className={st.cardHeader}>
                <Typography.Title>{`Рівень ${lvl.selectedLevel}`}</Typography.Title>
                <Typography.Title>{`Номер кроку ${num}`}</Typography.Title>
            </div>
            <SelectFieldsStep plainOptions={plainOptions} checkedList={checkedList}
                              setCheckedList={setCheckedList}/>
            <FormStep lvl={lvl} form={form} selectedFields={checkedList} handleCancel={handleCancel}/>
        </Drawer>
    );
}


export default CreateQuestionModal;
