import React, {useCallback, useEffect, useState} from "react"
import st from "./Rating.module.css"
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import list1 from "../../../images/index/i-list1.png"
import list2 from "../../../images/index/i-list2.gif"
import list3 from "../../../images/index/i-list3.png"
import list4 from "../../../images/index/i-list4.png"
import file1 from "../../../files/file1.pdf"
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import Preloader from "../../../components/Preloader/Preloader";
import {useHttp} from "../../../hooks/http.hook";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {Avatar, Card, Col, Image, List, notification, Row} from "antd";
import {useDocumentTitle} from "../../../hooks/title.hook";
import defaultPicture from "../../../images/defailt_picture.jpg"

function RatingPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Рейтинг гравців — Воби2");
    const {fillUsersList, usersList} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const navigate = useNavigate()

    const fetchUserList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/users`, "GET", null, {})
            fillUsersList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchUserList()
    }, [fetchUserList])

    if (loading) {

        return <Preloader/>
    }

    return (
        <div className={clsx(st.grIndex)}>
            <LandingHeader/>
            <Content className={clsx(st.main)}>
                <Row className={st.lvlList}>
                    <Col span={24}>
                        <List
                            grid={{
                                gutter: 8,
                                xs:1,
                                sm:1,
                                md:1,
                                lg: 2,
                                xl:2,
                                xxl:4
                            }}
                            dataSource={usersList}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={st.cardPosition}>
                                        <Card
                                            extra={<p className={st.extra}>{`Tелеграм: ${item.tg_username}`}</p>}
                                            className={st.lvlCard}
                                        >
                                            <Card.Meta
                                                avatar={!!item.rank[0]?.images[0]
                                                    ? <Image
                                                        style={{
                                                            height: 70,
                                                            borderRadius: 6,
                                                        }}
                                                        src={`${baseUrl}${item.rank[0].images[0]}`}
                                                    />
                                                    :  <Image
                                                        style={{
                                                            width: 70,
                                                            height: 70,
                                                            borderRadius: 6,
                                                        }}
                                                        src={defaultPicture}
                                                    />
                                                }
                                                title={item.name}
                                                description={item.rank_points ? item.rank_points : "0"}
                                            />
                                        </Card>
                                    </div>

                                </List.Item>
                            )}
                        />

                    </Col></Row>
            </Content>
            <LandingFooter/>
        </div>
    );
}


export default RatingPage;
