import React, {useState} from "react"
import {
    Modal,
    notification, Radio, Space,
} from "antd";
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";


function DeleteRanksModal({isModalOpen, setIsModalOpen, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {loading, error, request, clearError} = useHttp()
    const {fillRanksList, ranksList} = React.useContext(GameSettingContext);
    const [value, setValue] = useState("reservation");


    const onChange = (e) => {
        setValue(e.target.value);
    };


    const handleOk = async () => {
            try {
                let body = null
                switch (value) {
                    case "topRank": {
                        body = JSON.stringify(body = {
                            distribution: value,
                            dopRank: ranksList[isModalOpen.topRankIndex]._id,
                        })
                        break;
                    }
                    case "bottomRank": {
                        body = JSON.stringify(body = {
                            distribution: value,
                            dopRank: ranksList[isModalOpen.topRankIndex - 2]._id,
                        })
                        break;
                    }
                    case "delete":
                    case "reservation": {
                        body = JSON.stringify(body = {
                            distribution: value
                        })
                        break;
                    }
                    default: {
                        throw {message: "Помилка вибору варінту видалення"}
                    }
                }

                const data = await request(`${baseUrl}/api/ranks/delete/${isModalOpen.id}`, "PUT", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
                fillRanksList(data)
                clearError()
                setIsModalOpen({})
            } catch
                (e) {
                notification['error']({
                    message: e.message
                });
            }
        }
    ;
    const handleCancel = () => {
        setIsModalOpen({})
    };


    return (

        <Modal title="Видалення рангу"
               open={Object.keys(isModalOpen).length > 0}
               width={500}
               centered
               onOk={handleOk}
               onCancel={handleCancel}
        >

            {!ranksList[isModalOpen.topRankIndex - 1].reserved
                ?
                <p>Ви намагаєтесь видалити ранг.<br/> Будь-ласка виберіть один з запропонованих варіантів для видалення
                </p>
                : <p>Ви намагаєтесь видалити зарезервовану область для рангу.<br/> Будь-ласка виберіть один з
                    запропонованих варіантів для видалення</p>}

            <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                    {!ranksList[isModalOpen.topRankIndex - 1].reserved &&
                        <Radio value={"reservation"}>Видалення з резервацію діапазону</Radio>}
                    {(ranksList.length >= 2 && isModalOpen.topRankIndex - 2 >= 0) &&
                        <Radio value={"bottomRank"}>Видалення з перенесенням діапозону до меншого рангу</Radio>}
                    {ranksList.length - 1 >= isModalOpen.topRankIndex &&
                        <Radio value={"topRank"}>Видалення з перенесенням діапозону до вищого рангу</Radio>}
                    {ranksList.length - 1 === isModalOpen.topRankIndex - 1 &&
                        <Radio value={"delete"}>Видалити</Radio>}
                </Space>
            </Radio.Group>
        </Modal>

    );
}


export default DeleteRanksModal;
