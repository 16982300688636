import React from "react"
import {
    Form,
    Input,
    Modal,
    notification,
} from "antd";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";



function RemindPasswordModal({isModalOpen,setIsModalOpen, ...props}) {
    const {loading, error, request, clearError} = useHttp()

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const data = await request(
                `${baseUrl}/api/passwd/new/${values.name}`,
                "GET",
            );
            notification["success"]({
                message: data
            });
            clearError();
        } catch (e) {
            notification["error"]({
                message: e.message
            });
        }
    };

    return (
                <Modal title="Нагадування паролю"
                       okText="Нагадати"
                       cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={400} centered>

                    <Form form={form} onFinish={onFinish}
                          autoComplete="off" style={{marginTop: 20}}>
                        <Form.Item name={"name"} rules={[
                            {
                                required: true,
                                message: 'Відсутній логін',
                            },
                            {
                                max:25,
                                message: 'Дуже довгий логін',
                            },

                        ]}>
                            <Input placeholder="Логін з телеграму"/>
                        </Form.Item>
                    </Form>
                </Modal>

    );
}


export default RemindPasswordModal;
