import React, {useState} from "react"
import {
    Button,
    Drawer,
    Form,
    notification,
} from "antd";
import st from './CreateDirectoryModal.module.css'
import TextArea from "antd/es/input/TextArea";
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import DirectorSelect from "../../../selects/directorSelect/DirectorSelect";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import TagsModal from "../tagsModal/TagsModal";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";


function CreateDirectoryModal({isModalOpen, setIsModalOpen, fetchDirectory, tags, setTags, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const [isTagsModal, toggleTagsModal] = useState(false)


    const uploadDirectorPicture = (body) => {
        form.setFieldsValue({images: body})
    }


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onFinish = async (values) => {
        const body = JSON.stringify({...values})

        try {
            await request(`${baseUrl}/api/directory`, "POST", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            await fetchDirectory()
            clearError()
            form.resetFields();
            setIsModalOpen(false)
            notification['success']({
                message: "Успішно створенно"
            });
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (
        <Drawer title="Додавання довідки"
                open={isModalOpen}
                onClose={handleCancel}
                size={'large'}>

            <Form className={st.card} form={form} onFinish={onFinish}
                  autoComplete="off">
                <Form.Item name={"tags"} rules={[
                    {
                        required: true,
                        message: 'Відсутній хештег',
                    },
                ]}>
                    <DirectorSelect form={form} toggleTagsModal={toggleTagsModal} items={tags} setItems={setTags}/>
                </Form.Item>
                <Form.Item
                    name={"text"}
                    rules={[
                        {
                            required: true,
                            message: 'Будь-ласка, напишіть довідку',
                        },
                        {
                            max: 1500,
                            message: 'Дуже багато символів',
                        }
                    ]}
                >
                    <TextArea size={'large'} rows={15} placeholder="Текст довідки"/>
                </Form.Item>
                <Form.Item name={"images"}>
                    <UploadAvatar
                        placeholder={"Картинки для довідки"}
                        setImageFile={uploadDirectorPicture}
                        qaFileList={[]}
                    />
                </Form.Item>
                <Form.Item className={st.btnBlock}>
                    <Button disabled={loading}
                            size={"large"}
                            type="primary"
                            htmlType="submit"
                    >
                        Створити
                    </Button>
                    <Button
                        disabled={loading}
                        size={"large"}
                        danger
                        onClick={() => setIsModalOpen(false)}
                    >
                        Скасувати
                    </Button>
                </Form.Item>
            </Form>
            <TagsModal isModalOpen={isTagsModal} tags={tags} setTags={setTags}
                       setIsModalOpen={toggleTagsModal}/>
        </Drawer>

    );
}


export default CreateDirectoryModal;
