import React, {useCallback, useEffect, useState} from "react"
import st from "../LandingPage.module.css"
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import {useDocumentTitle} from "../../../hooks/title.hook";
import {useHttp} from "../../../hooks/http.hook";
import {EditorState} from "draft-js";
import Preloader from "../../../components/Preloader/Preloader";
import draftToHtml from "draftjs-to-html";
import {baseUrl} from "../../../config";
import {notification} from "antd";

function TermsPage() {
    const {loading, error, request, clearError} = useHttp()
    const [info, setInfo] = React.useState(EditorState.createEmpty());
    const [document_title, setDoucmentTitle] = useDocumentTitle("Умови користування — Воби2");
    const fetchInfo = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/block/termsPage`, "GET", null, {})
            data.title && setDoucmentTitle(data.title)
            setInfo(data)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])


    useEffect(() => {
        fetchInfo()
    }, [fetchInfo])


    if (loading) {
        return <Preloader/>
    }

    return (
        <div style={{minHeight: "100vh"}} className={clsx(st.grIndex)}>
            <LandingHeader />
            <main  className={clsx(st.bBasic, st.landing)} dangerouslySetInnerHTML={{__html: info.main_block && draftToHtml(JSON.parse(info?.main_block))}}/>
            {/*<main  className={clsx(st.bBasic, st.landing)}>*/}
           {/*<h1>Умови користування</h1>*/}
           {/*<h3 className={clsx( st.pId8)}>1. Основні положення</h3>*/}
           {/*<p>1.1. Ця&nbsp;Умови користування (далі&nbsp;&mdash; &laquo;Угода&raquo;) регулює відносини щодо використання*/}
           {/*    ресурсу &laquo;Воби2&raquo; (далі&nbsp;&mdash; &laquo;Ресурс&raquo;), які виникають між власником Ресурсу*/}
           {/*    та&nbsp;надавачем Послуг за&nbsp;цією Угодою (далі&nbsp;&mdash; &laquo;Виконавець&raquo;) та&nbsp;Вами*/}
           {/*    як&nbsp;користувачем Ресурсу (далі&nbsp;&mdash; &laquo;Користувач&raquo;). Виконавець та&nbsp;Користувач*/}
           {/*    надалі можуть згадуватись разом як&nbsp;&laquo;Сторони&raquo;, а&nbsp;кожен*/}
           {/*    окремо&nbsp;&mdash; як&nbsp;&laquo;Сторона&raquo;.</p>*/}
           {/*<p>1.2. Ресурс надає Користувачеві доступ до&nbsp;перегляду Контенту, а&nbsp;також можливість користування*/}
           {/*    іншими послугами Ресурсу в&nbsp;режимі реального часу за&nbsp;допомогою вебсайту: vobi2.com.ua*/}
           {/*    (далі &laquo;Сайту&raquo;), доступних на&nbsp;пристроях, підключених до&nbsp;мережі Інтернет*/}
           {/*    (далі&nbsp;&mdash; &laquo;Пристрої&raquo;).</p>*/}
           {/*<p>1.3. Контентом є&nbsp;аудіовізуальні, графічні, текстові та&nbsp;інші матеріали, доступ до&nbsp;перегляду*/}
           {/*    яких надається Користувачеві за&nbsp;допомогою Ресурсу.</p>*/}
           {/*<p>1.4. Угода є&nbsp;публічним договором приєднання, її&nbsp;умови однакові для всіх споживачів. Порядок*/}
           {/*    приймання умов Угоди та&nbsp;укладення договору між Користувачем та&nbsp;Виконавцем визначений нижче.*/}
           {/*    Будь ласка, уважно прочитайте цю&nbsp;Угоду перед використанням Ресурсу. Використання Ресурсу без*/}
           {/*    приймання умов Угоди не&nbsp;дозволяється.</p>*/}
           {/*<h3 className={clsx( st.pId8,st.pUp32p)}>2. Права та&nbsp;обов&rsquo;язки сторін</h3>*/}
           {/*<p>2.1. Використання Користувачем Ресурсу, будь-яких його послуг, перегляд та&nbsp;використання Контенту,*/}
           {/*    а&nbsp;також реєстрація Користувача в&nbsp;Сайті (створення облікового запису) є&nbsp;повним*/}
           {/*    і&nbsp;беззастережним прийняттям цієї Угоди й&nbsp;означає беззастережну згоду Користувача з&nbsp;усіма*/}
           {/*    пунктами цієї Угоди, з&nbsp;усіма її&nbsp;змінами та&nbsp;доповненнями й&nbsp;беззастережне прийняття*/}
           {/*    її&nbsp;умов. У&nbsp;разі незгоди Користувача з&nbsp;будь-якою з&nbsp;умов цієї Угоди, Користувач*/}
           {/*    зобов&rsquo;язаний відмовитися від подальшого використання Сайту.</p>*/}
           {/*<p>2.2. Користувач зобов&rsquo;язується використовувати Ресурс тільки в&nbsp;особистих некомерційних цілях,*/}
           {/*    дотримуватися умов цієї Угоди, не&nbsp;порушувати права та&nbsp;законні інтереси Виконавця*/}
           {/*    та&nbsp;правовласників Контенту.</p>*/}
           {/*<p>2.3. Користувач зобов&rsquo;язується ознайомитися з&nbsp;умовами цієї Угоди та&nbsp;самостійно*/}
           {/*    відстежувати їх&nbsp;зміни. Продовження використання Користувачем Ресурсу після будь-яких змін або*/}
           {/*    доповнень Угоди передбачає беззастережну згоду Користувача з&nbsp;такими змінами або доповненнями.*/}
           {/*    Незнання Користувачем чинних умов Угоди не&nbsp;звільняє Користувача від зобов&rsquo;язань, передбачених*/}
           {/*    в&nbsp;Угоді, а&nbsp;також відповідальності за&nbsp;їх&nbsp;неналежне виконання.</p>*/}
           {/*<p>2.4. Користувачі зобов&rsquo;язуються надавати правдиву інформацію щодо своїх анкетних та&nbsp;контактних*/}
           {/*    даних.</p>*/}
           {/*<p>2.5. Користувач гарантує, що&nbsp;під час використання Сайту не&nbsp;здійснює і&nbsp;не&nbsp;буде*/}
           {/*    здійснювати будь-яких дій, спрямованих на&nbsp;обхід технічних засобів захисту від несанкціонованого*/}
           {/*    використання Сайту, а&nbsp;також будь-яких інших дій, спрямованих на&nbsp;зміну функціональних*/}
           {/*    характеристик, дестабілізацію роботи Сайту.</p>*/}
           {/*<p>2.6. Користувач згодою із&nbsp;цією Угодою надає свою згоду на&nbsp;обробку й&nbsp;використання*/}
           {/*    персональних даних, отриманих за&nbsp;допомогою Ресурсу. Із&nbsp;подробицями збору та&nbsp;використання*/}
           {/*    можна ознайомитись на&nbsp;сторінці &laquo;Політика конфіденційності&raquo;.</p>*/}
           {/*<h3 className={clsx( st.pId8,st.pUp32p)}>3. Права, обов&rsquo;язки та&nbsp;гарантії виконавця</h3>*/}
           {/*<p>3.1. Виконавець зобов&rsquo;язується надавати Користувачу доступ до&nbsp;Ресурсу в&nbsp;порядку*/}
           {/*    й&nbsp;на&nbsp;умовах, передбачених цією Угодою.</p>*/}
           {/*<p>3.2. Виконавець має право на&nbsp;свій розсуд і&nbsp;без спеціального попередження Користувача обмежити*/}
           {/*    доступ Користувача до&nbsp;Ресурсу, певним одиницям Контенту, змінювати перелік та&nbsp;інші*/}
           {/*    характеристики Контенту, іншої інформації, розміщеної на&nbsp;сайті Ресурсу, а&nbsp;також функціональні*/}
           {/*    параметри Ресурсу. Надаючи згоду із&nbsp;цим пунктом, Користувач звільняє Виконавця від будь-яких видів*/}
           {/*    відшкодувань і&nbsp;компенсацій за&nbsp;реалізацію Виконавцем прав щодо цього пункту Угоди.</p>*/}
           {/*<p>3.3. Виконавець має право застосовувати будь-які дії, що&nbsp;не&nbsp;суперечать чинному законодавству,*/}
           {/*    з&nbsp;метою недопущення несанкціонованого доступу до&nbsp;Ресурсу, розміщеному на&nbsp;ньому Контенту,*/}
           {/*    дестабілізації роботи Ресурсу та&nbsp;інших дій, що&nbsp;порушують права та&nbsp;законні інтереси*/}
           {/*    Виконавця або правовласників Контенту.</p>*/}
           {/*<p>3.4. Виконавець надає Користувачу доступ до&nbsp;Контенту для легального некомерційного індивідуального*/}
           {/*    використання, способами, зазначеними в&nbsp;цій Угоді.</p>*/}
           {/*<p>3.5. Виконавець має право на&nbsp;свій власний розсуд, незалежно від попередження Користувача та&nbsp;без*/}
           {/*    пояснення причин, змінювати або видаляти будь-які розміщені Користувачем інформаційні матеріали,*/}
           {/*    коментарі тощо. При цьому Виконавець не&nbsp;несе відповідальності за&nbsp;будь-які збитки, які можуть*/}
           {/*    бути заподіяні Користувачу такими діями.</p>*/}
           {/*<p>3.6. Виконавець має право на&nbsp;власний розсуд встановлювати будь-які обмеження у&nbsp;використанні*/}
           {/*    Ресурсу, а&nbsp;також у&nbsp;будь-який час вносити зміни до&nbsp;цієї Угоди в&nbsp;односторонньому*/}
           {/*    порядку, без отримання згоди зі&nbsp;сторони Користувача.</p>*/}
           {/*<p>3.7. Виконавець може здійснювати технічну підтримку Користувачу через посилання на&nbsp;електронну поштову*/}
           {/*    адресу <a href="mailto:hello@vobi2.com.ua">hello@vobi2.com.ua</a>.</p>*/}
           {/*<h3 className={clsx( st.pId8,st.pUp32p)}>4. Відповідальність сторін</h3>*/}
           {/*<p>4.1. Сторони несуть відповідальність згідно з&nbsp;положеннями цього договору та&nbsp;відповідно*/}
           {/*    до&nbsp;чинного законодавства України.</p>*/}
           {/*<p>4.2. Порушення правил, встановлених цією Угодою, з&nbsp;боку Користувача може призвести до&nbsp;блокування*/}
           {/*    облікового засобу та&nbsp;обмеження доступу до&nbsp;Контенту.</p>*/}
           {/*<p>4.3. Користувач самостійно несе відповідальність за&nbsp;свої дії, пов&rsquo;язані з&nbsp;використанням*/}
           {/*    Ресурсу та&nbsp;Контенту, розміщенням інформації в&nbsp;інтерфейсі Ресурсу.</p>*/}
           {/*<p>4.4. Виконавець не&nbsp;несе відповідальності за&nbsp;будь-які дії чи&nbsp;бездіяльність особи*/}
           {/*    та&nbsp;їхні наслідки, пов&rsquo;язані із&nbsp;використанням інформації, отриманої з&nbsp;сайту*/}
           {/*    чи&nbsp;чат-боту.</p>*/}
           {/*<p>4.5. Виконавець не&nbsp;несе відповідальність за&nbsp;постійний і&nbsp;безперебійний доступ*/}
           {/*    до&nbsp;Сайту.</p>*/}
           {/*<p>4.6. Виконавець не&nbsp;несе відповідальність за&nbsp;зберігання персональних даних користувачів*/}
           {/*    у&nbsp;разі втручання третіх осіб у&nbsp;роботу Ресурсу. Водночас Виконавець зобов&rsquo;язується зробити*/}
           {/*    все від нього залежне для збереження цих даних надійно захищеними та&nbsp;конфіденційними згідно*/}
           {/*    з&nbsp;&laquo;Політикою конфіденційності&raquo;.</p>*/}
           {/*<h3 className={clsx( st.pId8,st.pUp32p)}>5. Прикінцеві положення</h3>*/}
           {/*<p>5.1. Виконавець може на&nbsp;свій розсуд змінювати умови цього договору в&nbsp;будь-якій формі, змісту*/}
           {/*    та&nbsp;в&nbsp;будь-якій послідовності без попередньої згоди з&nbsp;Користувачем.</p>*/}
       {/*</main>*/}
            <LandingFooter />
        </div>
    );
}


export default TermsPage;
