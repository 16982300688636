import React, {useCallback, useEffect, useState} from "react"
import st from "../LandingPage.module.css"
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import vobyHi from "../../../images/elements/voby-hi.gif"
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import {useDocumentTitle} from "../../../hooks/title.hook";
import {baseUrl} from "../../../config";
import {notification} from "antd";
import Preloader from "../../../components/Preloader/Preloader";
import draftToHtml from "draftjs-to-html";
import {useHttp} from "../../../hooks/http.hook";
import {EditorState} from "draft-js";

function ImprintPage() {
    const {loading, error, request, clearError} = useHttp()
    const [info, setInfo] = React.useState(EditorState.createEmpty());
    const [document_title, setDoucmentTitle] = useDocumentTitle("Вихідні дані — Воби2");
    const fetchInfo = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/block/outputDataPage`, "GET", null, {})
            data.title && setDoucmentTitle(data.title)
            setInfo(data)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])


    useEffect(() => {

        fetchInfo()

    }, [fetchInfo])


    if (loading) {

        return <Preloader/>
    }

    return (
        <div style={{minHeight:'100vh'}} className={clsx(st.grIndex)}>
            <LandingHeader />
            <main   className={clsx(st.bBasic, st.landing)} dangerouslySetInnerHTML={{__html: info.main_block && draftToHtml(JSON.parse(info?.main_block))}}/>


            <LandingFooter />
        </div>
    );
}

export default ImprintPage;
