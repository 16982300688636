import React, {useCallback, useEffect, useState} from "react"
import {Card, Col, FloatButton, Image, List, notification, Row, Typography} from "antd";
import st from "./PartnersPage.module.css"
import {Content} from "antd/es/layout/layout";
import {AppstoreAddOutlined, DeleteOutlined, EditOutlined,} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import Sidbar from "../../../components/sider/Sider";
import EditPartnerModal from "../../../components/Modals/partnersModals/editPartnersModals/EditPartnerModal";
import CreatePartnerModal from "../../../components/Modals/partnersModals/createPartnerModal/CreatePartnerModal";
import {useDocumentTitle} from "../../../hooks/title.hook";
import defaultPicture from  "../../../images/defailt_picture.jpg"
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


function PartnersPage() {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [document_title, setDoucmentTitle] = useDocumentTitle("Налаштування Партнерів — Воби2");
    const {fillPartnersList, partnersList, deletePartner} = React.useContext(GameSettingContext);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState({})
    const {loading, error, request, clearError} = useHttp()
    const navigate = useNavigate()


    const onDeletePartner = async (id) => {
        try {
            const data = await request(`${baseUrl}/api/partners/${id}`, "DELETE", null, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            deletePartner(data._id)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }


    const fetchPartnersList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/partners`, "GET", null, {})
            fillPartnersList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchPartnersList()
    }, [fetchPartnersList])



    if (loading) {

        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>
                <Row className={st.header}>
                    <Col span={20} className={st.title}><Typography.Title>Налаштування Партнерів</Typography.Title></Col>
                    <Col span={4} className={st.lvlAddBlock}><span className={st.lvlAdd}
                                                                   onClick={() => setIsModalCreateOpen(true)}>Додати Партнера<AppstoreAddOutlined /></span>
                    </Col>
                </Row>
                <Row className={st.lvlList}>
                    <Col span={24}>
                        <List
                            grid={{
                                gutter: 8,
                                xs:1,
                                sm:1,
                                md:1,
                                lg: 2,
                                xl:2,
                                xxl:4
                            }}
                            dataSource={partnersList}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={st.cardPosition}>
                                        <div className={st.cardActions}>
                                            <EditOutlined onClick={() => setIsModalEditOpen({...item})}/>
                                            <DeleteOutlined onClick={() => onDeletePartner(item._id)}/>
                                        </div>
                                        <Card className={st.lvlCard}>
                                            <Card.Meta
                                                avatar={item.images[0] ?<Image
                                                    src={`${baseUrl}${item.images[0]}`}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 6,
                                                    }}/> :  <Image
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 6
                                                    }}
                                                    alt="logo"
                                                    src={defaultPicture}

                                                />}


                                                title={item.name}
                                                description={<a onClick={()=> window.open(item.link, "_blank")}>{item.link}</a>}
                                            />

                                            {/*<iframe src={`${baseUrl}${item.images[0]}`} width="400px" height="400px"></iframe>*/}

                                        </Card>
                                    </div>

                                </List.Item>
                            )}
                        />

                    </Col></Row>



                    <FloatButton onClick={() => setIsModalCreateOpen(true)} tooltip={<div>Додати партнера</div>}
                                 icon={<AppstoreAddOutlined/>}/>


                <CreatePartnerModal isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen}/>
                {Object.keys(isModalEditOpen).length > 0 &&  <EditPartnerModal isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}/>}

            </Content>
        </>
    );
}


export default PartnersPage;
