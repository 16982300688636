import React, {useEffect, useMemo, useState} from "react"
import {Route, Routes, Navigate} from "react-router-dom";
import AuthPage from "./page/AdminPanel/AuthPage/AuthPage";
import AdministrationPage from "./page/AdminPanel/AdministrationPage/AdministrationPage";
import GameSettingsPage from "./page/AdminPanel/GameSettingsPage/GameSettingsPage";
import RewardsSettingsPage from "./page/AdminPanel/RewardsSettingsPage/RewardsSettingsPage";
import LevelDetailPage from "./page/AdminPanel/LevelDetailPage/LevelDetailPage";
import LandingPage from "./page/LandingPage/HomePage/LandingPage";
import AboutPage from "./page/LandingPage/About/AboutPage";
import ImprintPage from "./page/LandingPage/ImprintPage/ImprintPage";
import PolicyPage from "./page/LandingPage/PolicyPage/PolicyPage";
import TermsPage from "./page/LandingPage/TermsPage/TermsPage";
import UserAuthPage from "./page/UserCabinetPage/UserAuthPage/UserAuthPage";
import UserProfilePage from "./page/UserCabinetPage/UserProfilePage/UserProfilePage";
import RewardsPage from "./page/LandingPage/Rewards/RewardsPage";
import PartnersPage from "./page/AdminPanel/PartnersPage/PartnersPage";
import RatingPage from "./page/LandingPage/Rating/RatingPage";
import SettingsDirectoryPage from "./page/AdminPanel/SettingsDirectoryPage/SettingsDirectoryPage";
import DirectoryPage from "./page/LandingPage/DirectoryPage/DirectoryPage";
import DefaultPage from "./page/DefaultPage/DefaultPage";
import SocialMediaPage from "./page/AdminPanel/SocialMediaPage/SocialMediaPage";
import RanksPage from "./page/AdminPanel/RanksPage/RanksPage";
import SiteSettingsPage from "./page/AdminPanel/SiteSettingsPage/SiteSettingsPage";

const PrivateRoute = ({component, auth, redirectPath}) => {
    return auth ? component : <Navigate to={redirectPath} />
}

function useRoutes(isUser, isAdmin) {




    // if (!!isAdmin) {
    //     return (
    //         <Routes>
    //             <Route path={"/default"} exact element={<DefaultPage/>}/>
    //             <Route path={"/admin"} exact element={<Navigate to="/admin/gameSettings"/>}/>
    //             <Route path={"/admin/administration"} exact element={<AdministrationPage/>}/>
    //             <Route path={"/admin/gameSettings"} exact element={<GameSettingsPage/>}/>
    //             <Route path={"/admin/gameSettings/:id"} exact element={<LevelDetailPage/>}/>
    //             <Route path={"/admin/ranks"} exact element={<RanksPage/>}/>
    //             <Route path={"/admin/rewardsSettings"} exact element={<RewardsSettingsPage/>}/>
    //             <Route path={"/admin/directory"} exact element={<SettingsDirectoryPage/>}/>
    //             <Route path={"/admin/partners"} exact element={<PartnersPage/>}/>
    //             <Route path={"/admin/pageSettings"} exact element={<SiteSettingsPage/>}/>
    //             <Route path={"/admin/socialMedia"} exact element={<SocialMediaPage/>}/>
    //
    //
    //             <Route path={"/rating"} exact element={<RatingPage/>}/>
    //             <Route path={"/rewards"} exact element={<RewardsPage/>}/>
    //             <Route path={"/"} exact element={<LandingPage/>}/>
    //             <Route path={"/about"} exact element={<AboutPage/>}/>
    //             <Route path={"/directory"} exact element={<DirectoryPage/>}/>
    //             <Route path={"/imprint"} exact element={<ImprintPage/>}/>
    //             <Route path={"/policy"} exact element={<PolicyPage/>}/>
    //             <Route path={"/terms"} exact element={<TermsPage/>}/>
    //             <Route path={"/auth"} exact element={<UserAuthPage/>}/>
    //             <Route path="*" element={<Navigate to="/default"/>}/>
    //         </Routes>
    //     );
    // }
    // if (isUser) {
    //     return (
    //         <Routes>
    //             <Route path={"/default"} exact element={<DefaultPage/>}/>
    //             <Route path={"/auth"} exact element={<Navigate to="/cabinet"/>}/>
    //             <Route path={"/admin"} exact element={<AuthPage/>}/>
    //             <Route path={"/rating"} exact element={<RatingPage/>}/>
    //             <Route path={"/rewards"} exact element={<RewardsPage/>}/>
    //             <Route path={"/"} exact element={<LandingPage/>}/>
    //             <Route path={"/about"} exact element={<AboutPage/>}/>
    //             <Route path={"/directory"} exact element={<DirectoryPage/>}/>
    //             <Route path={"/imprint"} exact element={<ImprintPage/>}/>
    //             <Route path={"/policy"} exact element={<PolicyPage/>}/>
    //             <Route path={"/terms"} exact element={<TermsPage/>}/>
    //
    //             <Route path={"/cabinet"} exact element={<UserProfilePage/>}/>
    //             <Route path="*" element={<Navigate to="/default"/>}/>
    //         </Routes>
    //     );
    // }




    return (
        <Routes>
            <Route path={"/admin"} exact element={<PrivateRoute component={<AuthPage/>} auth={!isAdmin} redirectPath="/admin/gameSettings" />}/>
            <Route path="/admin/administration" exact element={<PrivateRoute component={<AdministrationPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/gameSettings" exact element={<PrivateRoute  component={<GameSettingsPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/gameSettings/:id" exact element={<PrivateRoute  component={<LevelDetailPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/ranks" exact element={ <PrivateRoute  component={<RanksPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/rewardsSettings" exact element={ <PrivateRoute  component={<RewardsSettingsPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/directory" exact element={ <PrivateRoute  component={<SettingsDirectoryPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/partners" exact element={ <PrivateRoute  component={<PartnersPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/pageSettings" exact element={ <PrivateRoute  component={<SiteSettingsPage/>} auth={isAdmin} redirectPath="/" />}/>
            <Route path="/admin/socialMedia" exact element={ <PrivateRoute  component={<SocialMediaPage/>} auth={isAdmin} redirectPath="/" />}/>


            <Route path={"/rating"} exact element={<RatingPage/>}/>
            <Route path={"/rewards"} exact element={<RewardsPage/>}/>
            <Route path={"/"} exact element={<LandingPage/>}/>
            <Route path={"/about"} exact element={<AboutPage/>}/>
            <Route path={"/directory"} exact element={<DirectoryPage/>}/>
            <Route path={"/imprint"} exact element={<ImprintPage/>}/>
            <Route path={"/policy"} exact element={<PolicyPage/>}/>
            <Route path={"/terms"} exact element={<TermsPage/>}/>

            <Route path="/auth" exact element={<PrivateRoute  component={<UserAuthPage/>} auth={!isUser} redirectPath="/cabinet" />}/>
            <Route path="/cabinet" exact element={<PrivateRoute  component={<UserProfilePage/>} auth={isUser} redirectPath="/auth" />}/>

            <Route path={"/default"} exact element={<DefaultPage/>}/>
            <Route path="*" element={<Navigate to="/default"/>}/>
        </Routes>
    )

}

export default useRoutes;
