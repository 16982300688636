import {notification, Upload} from 'antd';
import React, {useEffect, useState} from 'react';
import style from './UploadAvatar.module.css';
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


const UploadAvatar = ({setImageFile, qaFileList=[], placeholder = "Завантажити", dopParams, multiple=true, totalCount=10, ...props}) => {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {loading, error, request, clearError} = useHttp();
    const [defaultFileList, setFileList] = useState(
        qaFileList?.map((el, key) => {
            return {
                key: el,
                uid: key,
                name: `image${key}.png`,
                status: 'done',
                url: el,
            }
        })
    );
    const [valueField, setValueField] = useState(qaFileList.map(el => {
        return {url: el}
    }))






    const uploadImage = async options => {
        const {onSuccess, onError, file, onProgress} = options;

        const fmData = new FormData();
        fmData.append("image", file);
        try {
            if (valueField.length > totalCount) {
                throw new Error(`Максимальна кількість зображень: ${totalCount}, будь-ласка видаліть непотрібні зображення`);
            }
            const res = await request(
                `${baseUrl}/api/image`,
                "POST", fmData, {authorization: `Bearer ${token?.accessToken}`});

            await onSuccess(res, file);
            await setValueField(prevState => [...prevState, {name: file.name, url: res.image_src}])

        } catch (err) {
            notification["error"]({
                message: err.message,
            });
        }
    };


    const beforeUpload = (file, fileList) => {
        const isOK = (valueField.length+ fileList.length) <= totalCount
        const lastFile = fileList[fileList.length - 1]
        if (!isOK && (lastFile === file)) {
            notification["error"]({
                message: `Максимальна кількість зображень: ${totalCount}, будь-ласка видаліть непотрібні зображення`,
            });
        }
        return isOK || Upload.LIST_IGNORE;
    };

    useEffect(() => {
        setImageFile([...valueField.map(el => el.url)], dopParams)
    }, [valueField])


    const onRemove = async file => {
        const url = valueField.find(el => el.name === file.name) ? valueField.find(el => el.name === file.name).url : file.url
        const body = JSON.stringify({
            "image_url": url,
        })
        try {
            const res = await request(
                `${baseUrl}/api/image`,
                "DELETE", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`});
            setValueField(prevState => prevState.filter(el => el.url !== res))
        } catch (err) {
            notification["error"]({
                message: err.message,
            });
        }
    };

    const onPreview = async file => {
        const url = valueField.find(el => el.name === file.name) ? valueField.find(el => el.name === file.name).url : file.url
        window.open(`${baseUrl}${url}`, '_blank');
    };


    return (
        <>
            <Upload
                accept="image/*"
                maxCount={totalCount}
                customRequest={uploadImage}
                name="image"
                multiple={multiple}
                defaultFileList={defaultFileList}
                listType="picture-card"
                className={style.avatarUploader}
                // onChange={handleOnChange}
                onRemove={onRemove}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
            >
                <div type={"primary"}>{placeholder}</div>
            </Upload>
        </>
    );
};
export default UploadAvatar;