import {actions} from "./actionTypes";

export const  RanksInitialState = {
    ranksList: [],
};



export const RanksReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_RANKS_LIST:
            return {
                ...state,
                ranksList: [...action.payload]
            };
        case actions.ADD_RANK_TO_LIST:
            return {
                ...state,
                ranksList: [...state.ranksList, action.payload]
            };
        default:
            return state;
    }
};