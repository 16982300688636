import React from "react"
import {
    Form,
    Input, InputNumber,
    Modal,
    notification,
} from "antd";
import st from './CreatePartnerModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";





function CreatePartnerModal({isModalOpen,setIsModalOpen, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const {addPartner} = React.useContext(GameSettingContext);

    const uploadImage = (body) => {
        form.setFieldsValue( { images: body})
    }






    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
        const body = JSON.stringify({...values})

        try {
            const data = await request(`${baseUrl}/api/partners`, "POST", body,{'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            addPartner(data)
            clearError()
            setIsModalOpen(false)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };





    return (

                <Modal title="Додавання партнера"
                       okText="Додати"
                       cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} centered>

                    <Form className={st.card} form={form} onFinish={onFinish}
                          autoComplete="off">
                        <Form.Item name={"name"} rules={[
                            {
                                required: true,
                                message: 'Введіть назву партнера',
                            },
                            {
                                max: 128,
                                message: 'Максимальна кількість символів 128',
                            }
                        ]}>
                            <Input placeholder="Назва партнера"/>
                        </Form.Item>
                        <Form.Item
                            name={"link"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введіть посилання на веб-сайт партнера',
                                },
                            ]}
                        >
                            <Input placeholder="Посилання на сайт"/>
                        </Form.Item>
                        <Form.Item name={"images"}>
                            <UploadAvatar
                                placeholder={"Логотип Партнера"}
                                multiple={false}
                                setImageFile={uploadImage}
                                totalCount={1}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

    );
}


export default CreatePartnerModal;
