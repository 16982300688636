import {useState, useCallback} from 'react'
import {baseUrl} from "../config";
import {STORAGE_ADMIN, STORAGE_USER} from "../context/Reducers/AuthReducer";

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // const refreshUrl = !!authData ? '/api/admin/refresh' : '/api/refresh'

    const refreshUrl =  (headers) =>{
        console.log(headers)
        const token = headers?.authorization?.substr(7)
        const authData = JSON.parse(localStorage.getItem(STORAGE_ADMIN));
        if(authData?.accessToken === token){
            return '/api/admin/refresh'
        }
        return '/api/refresh'
    }

    const refreshStorage = useCallback((data) => {
        localStorage.setItem(data.info.role === 'admin' ? STORAGE_ADMIN : STORAGE_USER, JSON.stringify({
            info: data.info,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
        }));
    }, []);


    const request = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setLoading(true)
        try {
            let response = await fetch(url, {method, body, headers, credentials: "include"})
            let data = await response.json()
            if (response.status === 401 && data === 'jwt expired') {
                try {

                    const refreshToken = await fetch(`${baseUrl + refreshUrl(headers)}`, {
                        method: "GET",
                        body: null,
                        headers: {},
                        credentials: "include"
                    })
                    const refreshTokenData = await refreshToken.json()
                    await refreshStorage(refreshTokenData)
                    response = await fetch(url, {method, body, headers:{...headers,  authorization: `Bearer ${refreshTokenData?.accessToken}`}, credentials: "include"})
                    data = await response.json()
                } catch (e) {
                    console.error(e);
                }
            }

            if (!response.ok) {

                throw new Error(data.message || 'Что-то пошло не так')
            }

            setLoading(false)

            return data
        } catch (e) {
            setLoading(false)
            setError(e.message)
            throw e
        }
    }, [])

    const clearError = useCallback(() => setError(null), [])

    return {loading, request, error, clearError}
}