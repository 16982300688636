import React, {useEffect, useState} from "react";
import {
    Button, Drawer, Form, notification, Steps, theme, message, Typography,
} from "antd";
import EditQuestionCard from "./form/EditQuestionCard"
import st from './editQuestionModal.module.css'
import SelectFields from "./selectFields/SelectFieldsStep";

function EditQuestionModal({
                               modalInfo,
                               setModalInfo,
                               ...props
                           }) {



    const [checkedList, setCheckedList] = useState([]);
    const plainOptions = [{label: 'Запитання', value: "question"},
        {label: 'Картинка', value: "picture"},
        {label: 'Коментар', value: "text"},
        {label: 'Змінити текст кнопки', value: "button", disabled: checkedList.length < 1 }];

    useEffect(()=>{

        if(Object.keys(modalInfo).length > 0){
            setCheckedList(prevState => {
                return [...prevState,
                    !!modalInfo.question_text && modalInfo?.answers?.length > 0 && "question",
                    modalInfo?.question_images?.length > 0 && "picture",
                    !!modalInfo?.btn_text && "button",
                    ((!!modalInfo?.question_text && !modalInfo?.answers?.length > 0) || modalInfo.question_comment.length > 0) && "text"
                ]
            })
        }else {
            setCheckedList([])
        }
    }, [modalInfo])




    const handleCancel = () => {
        setModalInfo({});
    };

    return (
        <Drawer
                title={`Редагування  крок: ${modalInfo.number + 1} `}
                open={Object.keys(modalInfo).length > 0}
                onClose={handleCancel}
                size={'large'}
        >
            <SelectFields checkedList={checkedList} plainOptions={plainOptions} setCheckedList={setCheckedList}/>
            <EditQuestionCard card={modalInfo} setEdit={setModalInfo}  handleCancel={handleCancel} checkedList={checkedList} setCheckedList={setCheckedList} />
        </Drawer>
    );



}


export default EditQuestionModal;
