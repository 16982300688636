import React, {useCallback, useEffect, useState} from "react"
import {
    Col,
    FloatButton,
    Image,
    List,
    notification,
    Row,
    Tag,
    Typography
} from "antd";
import st from "./SettingsDirectoryPage.module.css"
import {Content} from "antd/es/layout/layout";
import {
    AppstoreAddOutlined, DeleteOutlined, EditOutlined
} from "@ant-design/icons";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import Sidbar from "../../../components/sider/Sider";
import CreateDirectoryModal from "../../../components/Modals/directoryModals/createDirectoryModal/CreateDirectoryModal";
import EditDirectoryModal from "../../../components/Modals/directoryModals/editDirectoryModal/EditDirectoryModal";
import {useDocumentTitle} from "../../../hooks/title.hook";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";



function SettingsDirectoryPage() {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [document_title, setDoucmentTitle] = useDocumentTitle("Налаштування Довідника — Воби2");
    const {fillArticleList, articleList} = React.useContext(GameSettingContext);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState({})
    const {loading, error, request, clearError} = useHttp()



    const [tags, setTags] = useState([]);

//tags
    const fetchTagsList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/tags`, "GET", null, {})
            setTags(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchTagsList()
    }, [fetchTagsList])



//tags

// all article
    const fetchDirectory = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/directory`, "GET", null, {})
            fillArticleList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])
    useEffect(() => {
        fetchDirectory()
    }, [fetchDirectory])
// all article

    const onDeleteArticle = async (id) => {
        try {
            await request(`${baseUrl}/api/directory/${id}`, "DELETE", null, {authorization: `Bearer ${token?.accessToken}`})
            await fetchDirectory()
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }

    const openEditModal =  (info) => {
        setIsModalEditOpen(info)
    }





    const genExtra = (item) => (
        <div className={st.cardActions}>
            <EditOutlined onClick={() => {
                openEditModal({...item})
            }
            }/>
            <DeleteOutlined onClick={(event) => {
                event.stopPropagation();
                onDeleteArticle(item._id)
            }}/>
        </div>
    );

    if (loading) {

        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>
                <Row className={st.header}>
                    <Col span={20} className={st.title}><Typography.Title>Налаштування Довідника</Typography.Title></Col>
                    <Col span={4} className={st.lvlAddBlock}><span className={st.lvlAdd}
                                                                   onClick={() => setIsModalCreateOpen(true)}>Додати Довідку<AppstoreAddOutlined /></span>
                    </Col>
                </Row>
                <Row className={st.lvlList}>
                    <Col span={24}>
                        <List
                            grid={{
                                gutter: 8,
                                column: 1
                            }}
                            dataSource={articleList}
                            pagination={{
                                onChange: (page) => {
                                    console.log(page);
                                },
                                pageSize: 5,
                            }}
                            renderItem={(item) => (
                                <List.Item key={item._id}
                                           className={st.articleCard}>

                                    <List.Item.Meta
                                        className={st.articleMeta}
                                        title={item?.tags?.map(tag => (
                                            <Tag  color="#ff8400"  className={st.articleTags} key={tag._id}>{tag.name}</Tag>
                                        ))}
                                        description={<span dangerouslySetInnerHTML={{__html: item.text}}/>
                                    }
                                    />
                                    <Image.PreviewGroup>
                                        {item?.images?.map((url)=><Image style={{padding: '10px'}} key={url} width={100} src={`${baseUrl}${url}`} />)}
                                    </Image.PreviewGroup>
                                    {genExtra(item)}
                                </List.Item>
                            )}
                        />

                    </Col></Row>



                    <FloatButton onClick={() => setIsModalCreateOpen(true)} tooltip={<div>Додати нову довідку</div>}
                                 icon={<AppstoreAddOutlined/>}/>

                <CreateDirectoryModal  isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen}
                                       fetchDirectory={fetchDirectory} tags={tags} setTags={setTags}/>
                {Object.keys(isModalEditOpen).length > 0 &&
                    <EditDirectoryModal isModalOpen={isModalEditOpen}
                                        setIsModalOpen={setIsModalEditOpen}
                                        fetchDirectory={fetchDirectory} tags={tags} setTags={setTags}/>}


            </Content>
        </>
    );
}


export default SettingsDirectoryPage;
