import React, {useEffect} from "react";
import {
    Button,
    Form,
    Input,
    InputNumber,
    notification,
    Space,

} from "antd";
import st from "./EditQuestionCard.module.css";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";


import {useHttp} from "../../../../../hooks/http.hook";
import {baseUrl} from "../../../../../config";
import GameSettingContext from "../../../../../context/GameSettingContext";
import UploadAvatar from "../../../../utils/UploadAvatar/uploadAvatar";
import Preloader from "../../../../Preloader/Preloader";
import {STORAGE_ADMIN} from "../../../../../context/Reducers/AuthReducer";

function EditQuestionCard({card, setEdit, handleCancel, checkedList, setCheckedList, ...props}) {
    const [form] = Form.useForm();
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {editQuestionItem} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp();


    const onFinish = async (values) => {
        try {
            if (Object.keys(values).length === 0) {
                throw new Error("Виберіть потрібні поля і заповніть їх");
            }
            const body = JSON.stringify({
                answers: values.answers ? values.answers : [],
                btn_text: values.btn_text ? values.btn_text : 'Наступне питання',
                question_comment: values.question_comment ? values.question_comment : [],
                question_images: values.question_images ? values.question_images : [],
                question_text: values.question_text ? values.question_text : '',
            })
            const data = await request(
                `${baseUrl}/api/qa/${card._id}`,
                "PUT",
                body,
                {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`}
            );

            editQuestionItem(data);
            notification["success"]({
                message: "Успішно зміненно",
            });
            handleCancel()
            clearError();


        } catch (e) {
            notification["error"]({
                message: e.message,
            });
        }

    };

    const uploadQuestionPicture = (body) => {
        form.setFieldsValue({question_images: body})
    }

    const uploadAnswerPicture = (body, key) => {
        const answerArray = form.getFieldValue('answers');
        answerArray[key] = {...answerArray[key], answer_images: body};
        form.setFieldValue('answers', answerArray);
    }


    useEffect(() => {
        if (Object.keys(card).length > 0) {
            form.setFieldsValue(card);
        } else form.resetFields();
    }, [card]);


    return (
        <Form
            className={st.card}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
        >

            <div className={st.carHearder}>
                {checkedList.includes("picture") &&
                    Object.keys(card).length > 0 && <Form.Item name={"question_images"} rules={[
                        {
                            required: true,
                            message: "Вставте картинку",
                        },
                    ]}>
                        <UploadAvatar
                            placeholder={"Фото для питання"}
                            setImageFile={uploadQuestionPicture}
                            qaFileList={card.question_images}
                        />
                    </Form.Item>}
            </div>
            {(checkedList.includes("question") || (checkedList.includes("text"))) && <Form.Item
                name={"question_text"}
                rules={[
                    {
                        required: true,
                        message: "Відсутній текст",
                    },
                ]}
            >
                <Input placeholder={checkedList.includes("question") ? 'Введіть запитання' : 'Введіть текст'}/>
            </Form.Item>}
            {checkedList.includes("question") && <Form.List
                name="answers"
                rules={[
                    {
                        required: true,
                        message: "Додайте варіанти відповіді",
                    },
                ]}
            >
                {(fields, {add, remove}, {errors}) => (
                    <>
                        {fields.map(({key, name, ...restField}) => (
                            <Space
                                key={key}
                                style={{
                                    display: "flex",
                                    marginBottom: 8,
                                    width: "100%",
                                }}
                                align="baseline"
                            >

                                <div className={st.answers}>
                                    <div className={st.answersHeader}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "points"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "number",
                                                    message: "Відсутні  бали",
                                                },
                                            ]}
                                        >
                                            <InputNumber placeholder="Бали" min={0} max={100}/>
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                            style={{fontSize: "25px", color: "#ff8400"}}
                                        />
                                    </div>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "text"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Відсутння  відповідь",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Введіть відповідь"/>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "comment"]}

                                    >
                                        <TextArea rows={4} placeholder="Введіть коментар"/>
                                    </Form.Item>
                                    <Form.Item  {...restField}
                                                name={[name, "answer_images"]}>
                                        <UploadAvatar
                                            dopParams={name}
                                            placeholder={"Фото для відповіді"}
                                            setImageFile={uploadAnswerPicture}
                                            qaFileList={!!card?.answers ? card.answers[key]?.answer_images : []}
                                        />
                                    </Form.Item>
                                </div>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined/>}
                            >
                                Додати варіант відповіді
                            </Button>
                            <Form.ErrorList errors={errors}/>
                        </Form.Item>
                    </>
                )}
            </Form.List>}

            {(checkedList.includes("text") && checkedList.includes("question")) &&
                <Form.List name="question_comment">
                    {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: "flex",
                                        marginBottom: 8,
                                        width: "100%",
                                    }}
                                    align="baseline"
                                >
                                    <div className={st.comment} key={key}>
                                        <MinusCircleOutlined style={{fontSize: "25px", color: "#ff8400"}}
                                                             onClick={() => remove(name)}/>
                                        <Form.Item
                                            {...restField}
                                            name={[name]}
                                        >
                                            <TextArea rows={4} placeholder="Введіть коментар"/>
                                        </Form.Item>

                                    </div>
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Додати коментар
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>}

            {checkedList.includes("button") && <Form.Item
                name={"btn_text"}
            >
                <Input placeholder="Введіть текст кнопки продовження"/>
            </Form.Item>}
            <Form.Item className={st.btnBlock}>
                <Button disabled={loading}
                        size={"large"}
                        type="primary"
                        htmlType="submit"
                >
                    Зберегти
                </Button>
                <Button
                    disabled={loading}
                    size={"large"}
                    danger
                    onClick={() => setEdit({})}
                >
                    Скасувати
                </Button>
            </Form.Item>
        </Form>
    );
}


export default EditQuestionCard;
