import React, {useEffect, useState} from "react"
import {
    Button,
    Card,
    Col,
    FloatButton,
    Form,
    Input,
    InputNumber,
    message,
    List,
    Space,
    Typography,
    Collapse, Image, Divider, Tooltip
} from "antd";
import st from "./QuestionCard.module.css"
import EditQuestionModal from "../Modals/questionModal/editQuestionModal/editQuestionModal";
import ShowQuestionCard from "./showCard/ShowQuestionCard";
import {FileImageOutlined} from "@ant-design/icons";
import {baseUrl} from "../../config";

const collapseHeader= (title, image)=>{
    return (!!title) ? <p dangerouslySetInnerHTML={{__html: title}}/> :
         <img
           key={image[0]}
           width={45}
           alt="image"
           src={`${baseUrl}${image[0]}`}
        />
}

function QuestionCard({content, levelName, ...props}) {
    const [modalInfo, setModalInfo] = useState({})

    return (
        <>
        <Col span={18} className={st.card}>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        // console.log(page);
                    },
                    pageSize: 10,
                }}
                dataSource={content}

                renderItem={(item) => (
                    <div className={st.margin}>
                    <Collapse >
                        <Collapse.Panel header={collapseHeader(item.question_text, item.question_images)} key={item._id} extra={<span className={st.number}>{`Крок: ${
                            item.number + 1
                        }`}</span>}>
                            <List.Item
                                key={item._id}
                            >
                                <ShowQuestionCard item={item} levelName={levelName} setEdit={setModalInfo}/>
                            </List.Item>
                        </Collapse.Panel>
                    </Collapse>
                    </div>
                )}
            />
        </Col>

                <EditQuestionModal modalInfo={modalInfo} setModalInfo={setModalInfo}/>
        </>
    );
}


export default QuestionCard;
