export const actions = {
//gameSetting
    //question action
    FILL_QUESTION_LIST: "FILL_QUESTION_LIST",
    CREATE_QUESTION_ITEM: "CREATE_QUESTION_ITEM",
    EDIT_QUESTION_ITEM: "EDIT_QUESTION_ITEM",
    DELETE_QUESTION_ITEM: "DELETE_QUESTION_ITEM",
    //levels action
    FILL_LEVELS_LIST: "FILL_LEVELS_LIST",
    ADD_LEVEL_TO_LIST: "ADD_LEVEL_TO_LIST",
    EDIT_LEVEL_TO_LIST: "EDIT_LEVEL_TO_LIST",
    DELETE_LEVEL_TO_LIST: "DELETE_LEVEL_TO_LIST",
    SET_SELECTED_LEVEL: "SET_SELECTED_LEVEL",
//gameSetting

//rewardSetting
    FILL_SALES_REWARD_LIST: "FILL_SALES_REWARD_LIST",
    FILL_REWARD_LIST: "FILL_REWARD_LIST",
    ADD_REWARD_TO_LIST: "ADD_REWARD_TO_LIST",
    EDIT_REWARD_TO_LIST: "EDIT_REWARD_TO_LIST",
    DELETE_REWARD_TO_LIST: "DELETE_REWARD_TO_LIST",
    DECREMENT_COUNT_OF_REWARD: "DECREMENT_COUNT_OF_REWARD",
//rewardSetting

//PartnersSetting
    FILL_PARTNER_LIST: "FILL_PARTNER_LIST",
    ADD_PARTNER_TO_LIST: "ADD_PARTNER_TO_LIST",
    EDIT_PARTNER_TO_LIST: "EDIT_PARTNER_TO_LIST",
    DELETE_PARTNER_TO_LIST: "DELETE_PARTNER_TO_LIST",
//PartnersSetting

//SocialMedia
    FILL_SOCIAL_MEDIA_LIST: "FILL_SOCIAL_MEDIA_LIST",
    ADD_SOCIAL_MEDIA_TO_LIST: "ADD_SOCIAL_MEDIA_TO_LIST",
    EDIT_SOCIAL_MEDIA_TO_LIST: "EDIT_SOCIAL_MEDIA_TO_LIST",
    DELETE_SOCIAL_MEDIA_TO_LIST: "DELETE_SOCIAL_MEDIA_TO_LIST",
//SocialMedia

//RanksSetting
    FILL_RANKS_LIST: "FILL_RANKS_LIST",
    ADD_RANK_TO_LIST: "ADD_RANK_TO_LIST",
//RanksSetting

//DirectorySetting
    FILL_ARTICLE_LIST: "FILL_ARTICLE_LIST",
    ADD_ARTICLE_TO_LIST: "ADD_ARTICLE_TO_LIST",
    EDIT_ARTICLE_TO_LIST: "EDIT_ARTICLE_TO_LIST",
    DELETE_ARTICLE_TO_LIST: "DELETE_ARTICLE_TO_LIST",
//DirectorySetting

// usersAdministration
    FILL_USERS_LIST:"FILL_USERS_LIST",
    EDIT_USER: "EDIT_USER",
    DELETE_USER: "DELETE_USER",
    BLOCKED_USER:"BLOCKED_USER",
// usersAdministration

//UserCabinet
    FILL_USERS_INFO:"FILL_USERS_INFO",
    EDIT_USERS_INFO:"EDIT_USERS_INFO",
    REMOVE_USERS_INFO: "REMOVE_USERS_INFO",
//UserCabinet

//AuthReducer
    LOGIN_USER:"LOGIN_USER",
    LOGOUT_USER:"LOGOUT_USER",
    LOGIN_ADMIN:"LOGIN_ADMIN",
    LOGOUT_ADMIN:"LOGOUT_ADMIN",
//AuthReducer


};
