import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Drawer,
    Form,
    Input,
    InputNumber, notification,
    Space,
    Typography,
} from "antd";
import st from "./CustomTab.module.css"
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Preloader from "../../Preloader/Preloader";

function CustomTab({tabKey, ...props}) {
    const {loading, error, request, clearError} = useHttp();
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [form] = Form.useForm();


    const [info, setInfo] = React.useState({});
    const fetchInfo = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/block/${tabKey}`, "GET", null, {})
            setInfo(data)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])


    useEffect(() => {
        fetchInfo()
    }, [fetchInfo])


    useEffect(() => {
        if (Object.keys(info).length > 0) {
            form.setFieldValue('title', info.title);
            const convertedState = convertFromRaw(JSON.parse(info.main_block))
            setEditorState(EditorState.createWithContent(
                convertedState
            ))
        } else {
            form.resetFields();
            setEditorState(EditorState.createEmpty())
        }
    }, [info]);


    const onEdit = async (values) => {
        const body = JSON.stringify({
            name: tabKey,
            title: values.title,
            main_block: JSON.stringify(convertToRaw(editorState.getCurrentContent()))

        })
        try {
            if (Object.keys(values).length === 0) {
                throw new Error("Виберіть потрібні поля і заповніть їх");
            }
            await request(
                `${baseUrl}/api/block/${info._id}`,
                "PUT",
                body,
                {'Content-Type': "application/json"}
            );

            notification["success"]({
                message: "Успішно зміненно",
            });
            clearError();
        } catch (e) {
            notification["error"]({
                message: e.message,
            });
        }
    };

    if (loading) {
        return <Preloader/>
    }

    return (
        <Form
            className={st.form}
            form={form}
            onFinish={onEdit}
            autoComplete="off"

        >
            <Form.Item
                name={"title"}
                rules={[
                    {
                        required: true,
                        message: "Заповніть назву сторінки",
                    },
                ]}
            >
                <Input placeholder={'Ведіть назву сторінки'}/>
            </Form.Item>

            <Form.Item
                name={"main_block"}

            >
                <Editor
                    editorState={editorState}
                    toolbarClassName={st.toolbarClassName}
                    wrapperClassName={st.wrapperClassName}
                    editorClassName={st.editorClassName}
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                    toolbarOnFocus
                />
            </Form.Item>

            <Form.Item className={st.btnBlock}>
                <Button disabled={loading}
                        size={"large"}
                        type="primary"
                        htmlType="submit"
                >
                    Зберегти
                </Button>
            </Form.Item>
        </Form>);
}


export default CustomTab;
