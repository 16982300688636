import React, {useCallback, useContext, useEffect, useState} from "react"
import {Button, Card, Col, FloatButton, List, Modal, notification, Row, Space, Typography} from "antd";
import st from "./LevelDetailPage.module.css"
import {Content} from "antd/es/layout/layout";
import {AppstoreAddOutlined, CommentOutlined, CustomerServiceOutlined, MenuOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import QuestionCard from "../../../components/questionCard/QuestionCard";
import CreateQuestionModal from "../../../components/Modals/questionModal/createQuestionModal/createQuestionModal";
import Preloader from "../../../components/Preloader/Preloader";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import Sidbar from "../../../components/sider/Sider";
import {useDocumentTitle} from "../../../hooks/title.hook";


function LevelDetailPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Редагування рівня — Воби2");
    const {fillQuestionList, setSelectedLevel, questionList, selectedLevel} = React.useContext(GameSettingContext);
    let {id} = useParams()
    const {loading, error, request, clearError} = useHttp()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };


    const fetchQuestionList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/qa/${id}`, "GET", null, {})
            setSelectedLevel(data.levelName)
            fillQuestionList(data.questions)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])


    useEffect(() => {

        fetchQuestionList()

    }, [fetchQuestionList])


    if (loading) {
        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>

                <Row>
                    <Col className={st.header} offset={1} span={22}>
                        <Typography.Title>{selectedLevel}</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col offset={1} span={22}>
                        <QuestionCard content={questionList} levelName={selectedLevel}/>
                    </Col>

                </Row>
                <FloatButton icon={<AppstoreAddOutlined/>} onClick={showModal} tooltip={<div>Додати новий крок</div>}/>
                <CreateQuestionModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} lvl={{selectedLevel, id}}
                                     num={questionList.length + 1}/>
            </Content>
        </>
    );
}


export default LevelDetailPage;
