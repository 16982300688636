import React, {useCallback, useEffect, useState} from "react"
import {
    Card,
    Col,
    Collapse,
    FloatButton,
    Image,
    List,
    notification,
    Row,
    Space,
    Typography
} from "antd";
import st from "./RewardsSettingsPage.module.css"
import {Content} from "antd/es/layout/layout";
import {
    AppstoreAddOutlined,
    DeleteOutlined, EditOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import Sidbar from "../../../components/sider/Sider";
import CreateRewardModal from "../../../components/Modals/rewardModal/createRewardModal/CreateRewardModal";
import EditRewardModal from "../../../components/Modals/rewardModal/editRewardModal/EditRewardModal";
import {useDocumentTitle} from "../../../hooks/title.hook";
import defaultPicture from "../../../images/defailt_picture.jpg"
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


function RewardsSettingsPage() {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [document_title, setDoucmentTitle] = useDocumentTitle("Налаштування Нагород — Воби2");
    const {
        fillSalesRewardsList,
        salesRewardList,
        fillRewardsList,
        rewardsList,
        deleteReward
    } = React.useContext(GameSettingContext);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState({})
    const {loading, error, request, clearError} = useHttp()
    const navigate = useNavigate()


    const onDeleteReward = async (id) => {
        try {
            const data = await request(`${baseUrl}/api/rewards/${id}`, "DELETE", null, {})
            deleteReward(data._id)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }


    const fetchRewardsList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/rewards`, "GET", null, {})
            fillRewardsList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    const fetchSalesRewardsList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/rewards/buy/users`, "GET", null, {authorization: `Bearer ${token?.accessToken}`})
            fillSalesRewardsList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchRewardsList()
        fetchSalesRewardsList()
    }, [])


    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>
                <Row className={st.header}>
                    <Col span={20} className={st.title}><Typography.Title>Налаштування Нагород</Typography.Title></Col>
                    <Col span={4} className={st.lvlAddBlock}><span className={st.lvlAdd}
                                                                   onClick={() => setIsModalCreateOpen(true)}>Створити Нагороду<AppstoreAddOutlined/></span>
                    </Col>
                </Row>
                <Row className={st.lvlList}>
                    <Col span={24}>
                        <Collapse className={st.collapse} defaultActiveKey={['1']}>
                            <Collapse.Panel header="Нагороди" key="1">
                                <List
                                    grid={{
                                        gutter: 8,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 2,
                                        xl: 2,
                                        xxl: 4
                                    }}
                                    dataSource={rewardsList}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div className={st.cardPosition}>
                                                <div className={st.cardActions}>
                                                    <EditOutlined onClick={() => setIsModalEditOpen({...item})}/>
                                                    <DeleteOutlined onClick={() => onDeleteReward(item._id)}/>
                                                </div>
                                                <Card
                                                    extra={<div className={st.extraBlock}>
                                                        <p className={st.extra}>{`Тип: ${item.type === 'digital' ? "цифровий" : "фізичний"}`}</p>
                                                        <p className={st.extra}>{`Кількість: ${item.count}`}</p>
                                                        <p className={st.extra}>{`Вартість: ${item.cost}`}</p>
                                                    </div>}

                                                >
                                                    <Card.Meta
                                                        avatar={item.images[0] ? <Image
                                                            src={`${baseUrl}${item.images[0]}`}
                                                            style={{
                                                                width: 70,
                                                                height: 70,
                                                                borderRadius: 6,
                                                            }}/> : <Image
                                                            style={{
                                                                width: 70,
                                                                height: 70,
                                                                borderRadius: 6
                                                            }}
                                                            alt="logo"
                                                            src={defaultPicture}
                                                        />}
                                                        title={item.name}
                                                        description={item.description}
                                                    />
                                                </Card>
                                            </div>

                                        </List.Item>
                                    )}
                                />
                            </Collapse.Panel>
                            <Collapse.Panel header="Історія обмінів нагороди" key="2">
                                <List
                                    grid={{
                                        gutter: 8,
                                        xs: 1,
                                        sm: 1,
                                        md: 1,
                                        lg: 1,
                                        xl: 1,
                                        xxl: 1
                                    }}
                                    dataSource={salesRewardList}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card className={st.salesCard}>
                                                <div className={st.salesBlock}>
                                                    {!!item?.user_info?.name &&
                                                        <p>{`Ім'я Користувача: ${item.user_info.name}`}</p>}
                                                    {!!item?.user_info?.tg_username &&
                                                        <p>{`Телеграм: ${item.user_info.tg_username}`}</p>}
                                                    {!!item?.user_info?.email &&
                                                        <p>{`Email: ${item.user_info.email}`}</p>}
                                                    {!!item?.reward_info?.name &&
                                                        <p>{`Нагорода: ${item.reward_info.name}`}</p>}
                                                    {!!item?.qty && <p>{`Кількість: ${item.qty}`}</p>}
                                                    {!!item?.shipping_name &&
                                                        <p>{`Ім'я Отримувача: ${item.shipping_name}`}</p>}
                                                    {!!item?.shipping_phone &&
                                                        <p>{`Номер Телефону: ${item.shipping_phone}`}</p>}
                                                    {!!item?.shipping_address &&
                                                        <p>{`Адреса: ${item.shipping_address}`}</p>}
                                                    {!!item?.createdAt &&
                                                        <p>{`Час створення замовлення: ${new Date(item.createdAt).toLocaleString()}`}</p>}
                                                </div>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Collapse.Panel>

                        </Collapse>


                    </Col></Row>



                    <FloatButton onClick={() => setIsModalCreateOpen(true)} tooltip={<div>Додати нагороду</div>}
                                 icon={<AppstoreAddOutlined/>}/>

                <CreateRewardModal isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen}/>
                {Object.keys(isModalEditOpen).length > 0 &&
                    <EditRewardModal isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}/>}

            </Content>
        </>
    );
}


export default RewardsSettingsPage;
