import React, {useState} from "react"
import {
    Button,
    Drawer,
    Form,
    notification,
} from "antd";
import st from './EditDirectoryModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import TextArea from "antd/es/input/TextArea";
import DirectorSelect from "../../../selects/directorSelect/DirectorSelect";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import TagsModal from "../tagsModal/TagsModal";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";


function EditDirectoryModal({isModalOpen, setIsModalOpen, fetchDirectory, tags, setTags,...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const [isTagsModal, toggleTagsModal] = useState(false)



    const handleCancel = () => {
        setIsModalOpen({});
    };

    const uploadDirectorPicture = (body) => {
        form.setFieldsValue({images: body})
    }

    const onFinish = async (values) => {
        const body = JSON.stringify({...values})
        try {
            await request(`${baseUrl}/api/directory/${isModalOpen._id}`, "PUT", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            await fetchDirectory()
            clearError()
            setIsModalOpen({})
            notification['success']({
                message: "Успішно зміненно"
            });
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (<Drawer title={`Редагування довідку`}
                    open={Object.keys(isModalOpen).length > 0}
                    onClose={handleCancel}
                    size={'large'}>

            <Form className={st.card} form={form} onFinish={onFinish}
                  autoComplete="off" initialValues={isModalOpen}>
                <Form.Item name={"tags"} rules={[
                    {
                        required: true,
                        message: 'Відсутній хештег',
                    },
                ]}>
                    <DirectorSelect form={form} defaultSelect={isModalOpen.tags} items={tags} setItems={setTags}
                                    toggleTagsModal={toggleTagsModal}/>
                </Form.Item>
                <Form.Item
                    name={"text"}
                    rules={[
                        {
                            required: true,
                            message: 'Будь-ласка, напишіть довідку',
                        },
                        {
                            max: 1500,
                            message: 'Дуже багато символів',
                        }
                    ]}
                >
                    <TextArea size={'large'} rows={15} placeholder="Текст довідки"/>
                </Form.Item>
                <Form.Item name={"images"}>
                    <UploadAvatar
                        placeholder={"Картинки для довідки"}
                        setImageFile={uploadDirectorPicture}
                        qaFileList={isModalOpen.images}
                    />
                </Form.Item>
                <Form.Item className={st.btnBlock}>
                    <Button disabled={loading}
                            size={"large"}
                            type="primary"
                            htmlType="submit"
                    >
                        Зберегти
                    </Button>
                    <Button
                        disabled={loading}
                        size={"large"}
                        danger
                        onClick={() => setIsModalOpen({})}
                    >
                        Скасувати
                    </Button>
                </Form.Item>
            </Form>
            <TagsModal isModalOpen={isTagsModal}
                       setIsModalOpen={toggleTagsModal}
                       setTags={setTags}
                       tags={tags}/>
        </Drawer>

    );
}


export default EditDirectoryModal;
