import React from "react"
import {Col, Row, Tabs, Typography} from "antd";
import st from "./SiteSettingsPage.module.css"
import {Content} from "antd/es/layout/layout";
import {
    AppstoreAddOutlined,
} from "@ant-design/icons";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import Sidbar from "../../../components/sider/Sider";
import {useDocumentTitle} from "../../../hooks/title.hook";
import CustomTab from "../../../components/tabsSiteSettings/customTab/CustomTab";
import CreatingTab from "../../../components/tabsSiteSettings/CreatingTab/CreatingTab";




function SiteSettingsPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Редактор Сторінок — Воби2");

    const {loading, error, request, clearError} = useHttp()

const itemTabs= [
    {
        label: `Головна сторінка`,
        key: 'indexPage',
        children: <CustomTab tabKey={'indexPage'}/>,
    },
    {
        label: `Про гру`,
        key: 'aboutGame',
        children: <CustomTab tabKey={'aboutGame'}/>,
    },
    {
        label: `Політика конфіденційності`,
        key: 'politicsPage',
        children: <CustomTab tabKey={'politicsPage'}/>,
    },
    {
        label: `Умови користування`,
        key: 'termsPage',
        children: <CustomTab tabKey={'termsPage'}/>,
    },
    {
        label: `Вихідні дані`,
        key: 'outputDataPage',
        children: <CustomTab tabKey={'outputDataPage'}/>,
    },
    //  {
    //     label: `Створити блок`,
    //     key: 'createPage',
    //     children: <CreatingTab />,
    // },
]


    if (loading) {

        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>
                <Row className={st.header}>
                    <Col span={24} className={st.title}><Typography.Title>Редактор Сторінок</Typography.Title></Col>
                </Row>

                <div>

                    <Tabs
                        defaultActiveKey="indexPage"
                        type="card"
                        items={itemTabs}
                    />
                </div>

            </Content>
        </>
    );
}


export default SiteSettingsPage;
