import {actions} from "./actionTypes";

export const DirectoryInitialState = {
    articleList: [],
};



export const DirectoryReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_ARTICLE_LIST:
            return {
                ...state,
                articleList: [...action.payload]
            };
        default:
            return state;
    }
};