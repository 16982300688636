import React, {useCallback, useEffect, useState} from "react"
import {Card, Col, FloatButton, List, notification, Row, Typography} from "antd";
import st from "./GameSettingsPage.module.css"
import {Content} from "antd/es/layout/layout";
import {
    AppstoreAddOutlined, DeleteOutlined, EditOutlined,
    FolderAddOutlined,
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import CreateLevelModal from "../../../components/Modals/levelModal/createLevelModal/CreateLevelModal";
import EditLevelModal from "../../../components/Modals/levelModal/editLevelModal/EditLevelModal";
import Sidbar from "../../../components/sider/Sider";
import {useDocumentTitle} from "../../../hooks/title.hook";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


function GameSettingsPage() {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [document_title, setDoucmentTitle] = useDocumentTitle("Налаштування Гри — Воби2");
    const {fillLevelsList, levelsList, removeLevelItem} = React.useContext(GameSettingContext);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState({})
    const {loading, error, request, clearError} = useHttp()
    const navigate = useNavigate()


    const deleteLevel = async (id) => {
        try {
            const data = await request(`${baseUrl}/api/lvl/${id}`, "DELETE", null, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            removeLevelItem(data._id)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }


    const fetchLevelsList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/lvl`, "GET", null, {})
            fillLevelsList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchLevelsList()
    }, [fetchLevelsList])



    if (loading) {

        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
        <Content className={st.gameSetting}>
            <Row className={st.header}>
                <Col span={20} className={st.title}><Typography.Title>Налаштування Гри</Typography.Title></Col>
                <Col span={4} className={st.lvlAddBlock}><span className={st.lvlAdd}
                                                                onClick={() => setIsModalCreateOpen(true)}>Створити Рівень<FolderAddOutlined/></span>
                </Col>
            </Row>
            <Row className={st.lvlList}>
                <Col span={24}>
                    <List
                        grid={{
                            gutter: 8,
                            xs:1,
                            sm:1,
                            md:1,
                            lg: 2,
                            xl:2,
                            xxl:4
                        }}
                        dataSource={levelsList}
                        renderItem={(item) => (
                            <List.Item>
                                <div className={st.cardPosition}>
                                <div className={st.cardActions}>
                                    <EditOutlined onClick={() => setIsModalEditOpen({...item})}/>
                                    <DeleteOutlined onClick={() => deleteLevel(item._id)}/>
                                </div>
                                <Card hoverable onClick={() => navigate(`/admin/gameSettings/${item._id}`)}
                                      className={st.lvlCard} title={item.name}>
                                    {`${item.contQuestions} запитання`}
                                </Card>
                                </div>

                            </List.Item>
                        )}
                    />

                </Col></Row>



                <FloatButton onClick={() => setIsModalCreateOpen(true)} tooltip={<div>Створити новий Рівень</div>}
                             icon={<AppstoreAddOutlined/>}/>


            <CreateLevelModal isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen}/>
            <EditLevelModal isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}/>

        </Content>
            </>
    );
}


export default GameSettingsPage;
