import {actions} from "./actionTypes";

export const RewardsSettingInitialState = {
    rewardsList: [],
    salesRewardList: [],
};



export const RewardsSettingReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_SALES_REWARD_LIST:
            return {
                ...state,
                salesRewardList: [...action.payload]
            };
        case actions.FILL_REWARD_LIST:
            return {
                ...state,
                rewardsList: [...action.payload]
            };
        case actions.ADD_REWARD_TO_LIST:
            return {
                ...state,
                rewardsList: [...state.rewardsList, action.payload]
            };
        case actions.DECREMENT_COUNT_OF_REWARD:
            const updatedReward = state.rewardsList.map((item) =>
                item._id === action.payload
                    ? {...item, count: item.count - 1 }
                    : item
            );

            return {
                ...state,
                rewardsList: updatedReward
            };
        case actions.EDIT_REWARD_TO_LIST:
            const updatedRewardsList = state.rewardsList.map((item) =>
                item._id === action.payload._id
                    ? {...action.payload}
                    : item
            );
            return {
                ...state,
                rewardsList: updatedRewardsList
            };

        case actions.DELETE_REWARD_TO_LIST:
            const filteredRewardsItem = state.rewardsList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                rewardsList: filteredRewardsItem
            };
        default:
            return state;
    }
};