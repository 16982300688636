import React from "react"
import {
    Form,
    Modal,
    notification, Slider,
} from "antd";
import st from './CreateRanksModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";





function CreateRanksModal({isModalOpen,setIsModalOpen, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const {addRank, ranksList} = React.useContext(GameSettingContext);

    const newMinRange = ranksList[ranksList.length - 1] ? ranksList[ranksList.length - 1].max + 1 : 0

    const uploadImage = (body) => {
        form.setFieldsValue( { images: body})
    }
    const uploadAvatarImage = (body) => {
        if(body.length > 0){
            form.setFieldsValue( { avatar_image: body[0]})
        }else {
            form.setFieldsValue( { avatar_image: null})
        }
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {
         const body = JSON.stringify({
             min:values.range[0],
             max:values.range[1],
             images: values.images,
             avatar_image: values.avatar_image
        })
        try {
            const data = await request(`${baseUrl}/api/ranks`, "POST", body,{'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            addRank(data)
            clearError()
            setIsModalOpen(false)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };





    return (

                <Modal title="Додавання рангу"
                       okText="Додати"
                       cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} centered>

                    <Form className={st.card} form={form} onFinish={onFinish}
                          autoComplete="off">
                        <Form.Item name="range"  rules={[
                            {
                                required: true,
                                message: 'Відсутній діапозон',
                            },
                        ]}>
                            <Slider
                                range={true}
                                min={newMinRange}
                                max={newMinRange +300}
                            />
                        </Form.Item>
                        <Form.Item name={"images"}>
                            <UploadAvatar
                                placeholder={"Карткочка рангу"}
                                multiple={false}
                                setImageFile={uploadImage}
                                totalCount={1}
                            />
                        </Form.Item>
                        <Form.Item name={"avatar_image"}>
                            <UploadAvatar
                                placeholder={"Аватарка рангу"}
                                multiple={false}
                                setImageFile={uploadAvatarImage}
                                totalCount={1}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

    );
}


export default CreateRanksModal;
