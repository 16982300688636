import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, ConfigProvider, Divider, Input, notification, Select, Space} from 'antd';
import {useEffect, useRef, useState} from 'react';
import st from './CustomSelect.module.css'
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


const DirectorSelect = ({form, defaultSelect = [], toggleTagsModal, items, setItems, ...props}) => {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {loading, error, request, clearError} = useHttp();
    const inputRef = useRef(null);
    const [name, setName] = useState('')
    const [selectValue, setSelectValue] = useState([])


    useEffect(() => {
        defaultSelect.length > 0 && setSelectValue(defaultSelect.map(({_id}) => _id))
    }, [defaultSelect])


    useEffect(() => {
        form.setFieldsValue({tags: selectValue})
    }, [selectValue])


    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = async (e) => {
        try {

            e.preventDefault();
            const body = JSON.stringify({name: name})
            const data = await request(
                `${baseUrl}/api/tags`,
                "POST",
                body,
                {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`}
            );
            clearError();
            setItems([...items, data]);
            setName('');
            setTimeout(() => {
                inputRef.current?.focus();
            }, 0);

        } catch (e) {
            notification["error"]({
                message: e.message,
            });
        }
    };


    const handleSelectValue = (e) => {
        setSelectValue(e)
    };

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimaryBg: '#ff8400',
                },
            }}
        >
            <Select
                getPopupContainer={triggerNode => triggerNode.parentElement}
                size={'large'}
                mode="multiple"

                value={selectValue}
                filterOption={true}
                optionFilterProp="label"
                placeholder="Вибергіть теги"
                onChange={handleSelectValue}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider
                            style={{
                                margin: '8px 0',
                            }}
                        />
                        <Space
                            style={{
                                padding: '0 8px 4px',
                            }}
                        >
                            <Input
                                placeholder="Назва нового тегу"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                                size={'middle'}
                            />
                            <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                Створити тег
                            </Button>
                            <Button type="text" icon={<MinusOutlined/>} onClick={() => toggleTagsModal(true)}>
                                Видалити тег
                            </Button>
                        </Space>
                    </>
                )}
                options={items.map((item) => ({
                    label: item.name,
                    value: item._id,
                    className: st.select
                }))}
            />
        </ConfigProvider>
    );
};
export default DirectorSelect;