import React, {useCallback, useEffect, useState} from "react"
import st from "./DirectoryPage.module.css"
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import list1 from "../../../images/index/i-list1.png"
import list2 from "../../../images/index/i-list2.gif"
import list3 from "../../../images/index/i-list3.png"
import list4 from "../../../images/index/i-list4.png"
import file1 from "../../../files/file1.pdf"
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import {baseUrl} from "../../../config";
import {Typography, Col, notification, Row, Space, Card, Image, Divider} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useDocumentTitle} from "../../../hooks/title.hook";

function DirectoryPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Довідник – Воби2");
    const {fillArticleList} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const [titles, setTitles] = useState([])
    const [info, setInfo] = useState([])

    const fetchDirectory = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/tags`, "GET", null, {})
            setTitles(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])



    const fetchInfo = useCallback(async (id) => {
        try {
            const data = await request(`${baseUrl}/api/directory/tag/${id}`, "GET", null, {})
            setInfo(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchDirectory()
    }, [fetchDirectory])

    const showTitles = titles.map(el => <span onClick={() => fetchInfo(el._id)} className={clsx(st.titles,
        el._id === info[0]?.tag_id && st.active
    )}
                                              key={el._id}>{`#${el.name}`}</span>)

    if (loading) {
        return <Preloader/>
    }

    return (
        <div className={clsx(st.grIndex)}>
            <LandingHeader/>
            <Content className={clsx(st.main)}>
                <Row>
                    <Col span={24}>
                        <Typography.Title className={st.titleName}>Довідник</Typography.Title>
                    </Col>
                    <Col offset={1} span={23} className={st.titleInfo}>
                        <Typography.Title>Тут ми зібрали найважливіші інструкції, які точно варто знати й
                            пам’ятати.</Typography.Title>
                        <Typography.Title>Для пошуку інформації просто выбери той хештег, що описує ситуацію, про яку
                            хочеш дізнатися більше.</Typography.Title>
                    </Col>
                </Row>


                <Space size={[16, 16]} wrap>
                    {showTitles}
                </Space>

                <Row className={st.cardBlock}>
                    <Col span={24}>
                        {
                            info.length > 0 ? info.map(el => <Card className={st.card}>

                                <Card.Meta
                                    description={<p dangerouslySetInnerHTML={{__html: el.text}}/>}
                                />
                                <div style={{marginTop: 20}}>
                                    <Image.PreviewGroup>
                                        {el?.images.length > 0 &&
                                            el?.images?.map((url) => <div
                                                style={{margin: '10px', display: 'inline-block'}}>
                                                <Image key={url} style={{maxWidth: 350}} src={`${baseUrl}${url}`}/>
                                            </div>)}
                                    </Image.PreviewGroup>
                                </div>
                            </Card>) : <Typography.Title>Вибери потрібний хештег</Typography.Title>
                        }
                    </Col>
                </Row>
            </Content>
            <LandingFooter/>
        </div>
    );
}


export default DirectoryPage;
