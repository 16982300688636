import React from "react"
import {
    Form,
    Input,
    Modal,
    notification,
} from "antd";
import st from './EditPartnerModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";







function EditPartnerModal({isModalOpen,setIsModalOpen, lvl, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {request, clearError} = useHttp()
    const {editPartnersList} = React.useContext(GameSettingContext);

    const uploadImage = (body) => {
        form.setFieldsValue( { images: body})
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen({});
    };



    const onFinish = async (values) => {
        const body = JSON.stringify({...values})
        try {
            const data = await request(`${baseUrl}/api/partners/${isModalOpen._id}`, "PUT", body,{'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            editPartnersList(data)
            clearError()
            setIsModalOpen({})

        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };





    return (

                <Modal title={`Редагування партнера`}
                       okText="Зберегти"
                       cancelText="Скасувати" open={Object.keys(isModalOpen).length > 0} onOk={handleOk} onCancel={handleCancel} width={500} centered>

                    <Form className={st.card} form={form} onFinish={onFinish}
                          autoComplete="off" initialValues={isModalOpen}>
                        <Form.Item name={"name"} rules={[
                            {
                                required: true,
                                message: 'Введіть назву партнера',
                            },
                        ]}>
                            <Input placeholder="Назва партнера"/>
                        </Form.Item>
                        <Form.Item
                            name={"link"}
                            rules={[
                                {
                                    required: true,
                                    message: 'Введіть посилання на веб-сайт партнера',
                                },
                                {
                                    max: 128,
                                    message: 'Максимальна кількість символів 128',
                                }
                            ]}
                        >
                            <Input placeholder="Посилання на сайт"/>
                        </Form.Item>
                        <Form.Item name={"images"}>
                            <UploadAvatar
                                placeholder={"Логотип Партнера"}
                                multiple={false}
                                setImageFile={uploadImage}
                                qaFileList={isModalOpen.images}
                                totalCount={1}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

    );
}


export default EditPartnerModal;
