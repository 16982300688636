import React, {useState} from "react"
import {
    Button,
    Drawer,
    Form,
    notification, Switch,
} from "antd";
import st from './AdministrationModal.module.css'
import TextArea from "antd/es/input/TextArea";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import AdministrationSelect from "../../selects/administrationSelect/AdministrationSelect";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";


function AdministrationModal({isModalOpen, setIsModalOpen, usersItems, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const [isAllUser, toggleAllUser] = useState(false)
    const changeUsersField = (body) => {
        form.setFieldsValue({ids: body})
    }


    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onFinish = async (values) => {
        const body = JSON.stringify({
            ids: values.ids,
            message: values.message
        })
        try {
            const data = await request(`${baseUrl}/api/notify${isAllUser ?'/all' : ''}`, "POST", body, {
                'Content-Type': "application/json",
                authorization: `Bearer ${token?.accessToken}`
            })
            clearError()
            form.resetFields();
            setIsModalOpen(false)
            notification['success']({
                message: data
            });
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (
        <Drawer title="Оповіщення користувачів"
                open={isModalOpen}
                onClose={handleCancel}
                size={'large'}>
            <p className={st.switchLabel}><Switch checked={isAllUser} onChange={(e)=>toggleAllUser(e)}/> Оповістити всіх користувачам</p>

            <Form className={st.card} form={form} onFinish={onFinish}
                  autoComplete="off">
                {!isAllUser && <Form.Item name={"ids"} rules={[
                    {
                        required: true,
                        message: 'Виберіть користувачів',
                    },
                ]}>
                    <AdministrationSelect changeUsersField={changeUsersField} items={usersItems}/>
                </Form.Item>}
                <Form.Item
                    name={"message"}
                    rules={[
                        {
                            required: true,
                            message: 'Будь-ласка, напишіть повідомлення',
                        },
                        {
                            max: 1500,
                            message: 'Дуже багато символів',
                        }
                    ]}
                >
                    <TextArea rows={10} size={'large'} placeholder="Повідомлення"/>
                </Form.Item>
                <Form.Item className={st.btnBlock}>
                    <Button disabled={loading}
                            size={"large"}
                            type="primary"
                            htmlType="submit"
                    >
                        Надіслати
                    </Button>
                    <Button
                        disabled={loading}
                        size={"large"}
                        danger
                        onClick={() => setIsModalOpen(false)}
                    >
                        Скасувати
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>

    );
}


export default AdministrationModal;
