import {actions} from "./actionTypes";

export const PartnersInitialState = {
    partnersList: [],
};



export const PartnersReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_PARTNER_LIST:
            return {
                ...state,
                partnersList: [...action.payload]
            };
        case actions.ADD_PARTNER_TO_LIST:
            return {
                ...state,
                partnersList: [...state.partnersList, action.payload]
            };
        case actions.EDIT_PARTNER_TO_LIST:
            const updatedPartnerList = state.partnersList.map((item) =>
                item._id === action.payload._id
                    ? {...action.payload}
                    : item
            );
            return {
                ...state,
                partnersList: updatedPartnerList
            };

        case actions.DELETE_PARTNER_TO_LIST:
            const filteredPartnerItem = state.partnersList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                partnersList: filteredPartnerItem
            };
        default:
            return state;
    }
};