import {
    AuditOutlined, EditOutlined, GiftOutlined, GlobalOutlined, LinkOutlined,
    LogoutOutlined, SettingOutlined, StarOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import React, {useContext, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import st from "./Sider.module.css"
import {useHttp} from "../../hooks/http.hook";
import {baseUrl} from "../../config";
import GameSettingContext from "../../context/GameSettingContext";

const {Sider} = Layout;

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}


const items = [
    getItem('Гра', '/admin/gameSettings', <SettingOutlined />),
    getItem('Адміністрування', '/admin/administration', <UserSwitchOutlined/>),
    getItem('Ранги', '/admin/ranks', <StarOutlined />),
    getItem('Винагороди', '/admin/rewardsSettings', <GiftOutlined/>),
    getItem('Довідник', '/admin/directory', <GlobalOutlined />),
    getItem('Партнери', '/admin/partners', <AuditOutlined/>),
    getItem('Соціальні мережі', '/admin/socialMedia', <LinkOutlined />),
    getItem('Редактор сторінок', '/admin/pageSettings', <EditOutlined />),
    getItem('Вихід', 'logout', <LogoutOutlined/>),
]

function Sidbar() {
    const {logoutAdmin} = React.useContext(GameSettingContext);
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const {request} = useHttp()


    const handleLogout = async ()=>{
        await request(`${baseUrl + '/api/admin/logout'}`, 'POST',null,{'Content-Type': "application/json"})
        logoutAdmin()
        navigate("/")
    }

    function selectHandler({key}) {
        if(key === "logout"){
            handleLogout()
        }else navigate(key)
    }







    return <Sider className={st.sider} collapsible



    >
        <div className="logo"/>
        <Menu theme="dark" mode="inline" selectedKeys={pathname} onSelect={selectHandler} items={items}/>
    </Sider>

}

export default Sidbar;