import React, {useEffect} from "react"
import {
    Form,
    Input, InputNumber,
    Modal,
    notification, Select,
} from "antd";
import st from './EditRewardModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import TextArea from "antd/es/input/TextArea";
import UploadAvatar from "../../../utils/UploadAvatar/uploadAvatar";


function EditRewardModal({isModalOpen, setIsModalOpen, lvl, ...props}) {
    const [form] = Form.useForm();
    const initValue = {...isModalOpen}
    const {request, clearError} = useHttp()
    const {editRewardsList} = React.useContext(GameSettingContext);

    const uploadImage = (body) => {
        form.setFieldsValue({images: body})
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen({});

    };


    const onFinish = async (values) => {
        try {
            const body = JSON.stringify(values)
            const data = await request(`${baseUrl}/api/rewards/${isModalOpen._id}`, "PUT", body, {'Content-Type': "application/json"})
            editRewardsList(data)

            clearError()
            setIsModalOpen({})
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (

        <Modal title={`Редагування нагороди`}
               okText="Редагувати"
               getContainer={false}
               cancelText="Скасувати" open={Object.keys(isModalOpen).length > 0} onOk={handleOk} onCancel={handleCancel}
               width={500} centered>

            <Form className={st.card} form={form} initialValues={initValue} onFinish={onFinish}
                  autoComplete="off">


                <Form.Item name={"name"} rules={[
                    {
                        required: true,
                        message: 'Відсутня назва',
                    },
                    {
                        max: 128,
                        message: 'Максимальна кількість символів 128',
                    }
                ]}>
                    <Input placeholder="Назва нагороди"/>
                </Form.Item>

                <Form.Item style={{marginBottom: 0}}>
                    <Form.Item
                        name={"type"}
                        style={{display: 'inline-block', width: 'calc(33% - 4px)'}}
                        rules={[{required: true, message: 'Відсутній тп нагороди'}]}
                    >
                        <Select placeholder="Вид нагороди">
                            <Select.Option value="digital">Цифровий</Select.Option>
                            <Select.Option value="physical">Фізичний</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={"count"}
                        style={{display: 'inline-block', width: 'calc(33% - 4px)', margin: '0 8px'}}
                        rules={[
                            {
                                required: true,
                                type: "number",
                                message: "Відсутня кількість",
                            },
                        ]}
                    >
                        <InputNumber placeholder="Кількість" min={0}/>
                    </Form.Item>
                    <Form.Item
                        name={"cost"}
                        style={{display: 'inline-block', width: 'calc(33% - 4px)'}}
                        rules={[
                            {
                                required: true,
                                type: "number",
                                message: "Відсутня вартість",
                            },
                        ]}
                    >
                        <InputNumber placeholder="Вартість" min={0}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name={"description"}>
                    <TextArea rows={4} placeholder="Опис нагороди"/>
                </Form.Item>
                <Form.Item name={"images"}>
                    <UploadAvatar
                        placeholder={"Зображення Нагороди"}
                        multiple={false}
                        setImageFile={uploadImage}
                        qaFileList={isModalOpen.images}
                        totalCount={1}
                    />
                </Form.Item>
            </Form>
        </Modal>

    );
}


export default EditRewardModal;
