import React from "react"
import {
    Form,
    Input,
    Modal,
    notification,
} from "antd";
import st from './BuyRewardModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";





function BuyRewardModal({isModalOpen,setIsModalOpen, ...props}) {
    const [form] = Form.useForm();
    const {loading, error, request, clearError} = useHttp()
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {decCountReward} = React.useContext(GameSettingContext);


    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen({});
    };



    const onFinish = async (values) => {
        try {
            const body = JSON.stringify(values)


            const data = await request(`${baseUrl}/api/rewards/buy/${isModalOpen._id}`, "POST", body, {
                'Content-Type': "application/json",
                authorization: `Bearer ${token?.accessToken}`
            })
            decCountReward(isModalOpen._id)
            notification['success']({
                message: data
            });
            clearError()
            setIsModalOpen({});
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };





    return (

                <Modal title="Купівля нагороди"
                       okText="Купити"
                       cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} centered>

                    <Form className={st.card} form={form} onFinish={onFinish}
                          autoComplete="off">


                        <Form.Item name={"shipping_name"} rules={[
                            {
                                required: true,
                                message: "Відсутнє ім'я",
                            },
                        ]}>
                            <Input placeholder="Ім'я Отримувача"/>
                        </Form.Item>
                        <Form.Item
                            name="shipping_phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Відсутній номер телефону',
                                },
                            ]}
                        >
                            <Input
                                addonBefore={"+380"}
                                placeholder="Номер Телефону"
                                style={{
                                    width: '100%',
                                }}
                            />
                        </Form.Item>
                        <Form.Item name={"shipping_address"} rules={[
                            {
                                required: true,
                                message: 'Відсутня адреса',
                            },
                        ]}>
                            <Input placeholder="Повна Адреса Отримувача"/>
                        </Form.Item>



                    </Form>
                </Modal>

    );
}


export default BuyRewardModal;
