import React, {createContext, useReducer} from "react";
import {gameSettingInitialState, gameSettingReducer} from "./Reducers/GameSettingReducer";
import {actions} from "./Reducers/actionTypes";
import {usersAdministrationInitialState, usersAdministrationReducer} from "./Reducers/UsersAdministrationReducer";
import {UserCabinetInitialState, UserCabinetReducer} from "./Reducers/UserCabinetReducer";
import {RewardsSettingInitialState, RewardsSettingReducer} from "./Reducers/RewardsSettingReducer";
import {PartnersInitialState, PartnersReducer} from "./Reducers/PartnersReducer";
import {DirectoryInitialState, DirectoryReducer} from "./Reducers/DirectoryReducer";
import {SocialMediaInitialState, SocialMediaReducer} from "./Reducers/SocialMediaReducer";
import {RanksInitialState, RanksReducer} from "./Reducers/RanksReducer";
import {AuthReducerInitialState, AuthReducerReducer} from "./Reducers/AuthReducer";


const combineReducers = (slices) => (state, action) =>
    Object.keys(slices).reduce(
        (acc, prop) => ({
            ...acc,
            [prop]: slices[prop](acc[prop], action),
        }),
        state
    );


const StoreContext = createContext();

export const StoreProvider = ({children}) => {

    const initialState = {
        gameSetting: gameSettingInitialState,
        usersAdministration: usersAdministrationInitialState,
        userCabinet: UserCabinetInitialState,
        rewardSetting: RewardsSettingInitialState,
        partners: PartnersInitialState,
        directory: DirectoryInitialState,
        socialMedia: SocialMediaInitialState,
        ranks: RanksInitialState,
        auth: AuthReducerInitialState,

    };
    const rootReducer = combineReducers({
            gameSetting: gameSettingReducer,
            usersAdministration: usersAdministrationReducer,
            userCabinet: UserCabinetReducer,
            rewardSetting: RewardsSettingReducer,
            partners: PartnersReducer,
            directory: DirectoryReducer,
            socialMedia: SocialMediaReducer,
            ranks: RanksReducer,
            auth: AuthReducerReducer
        }
    );

    const [state, dispatch] = useReducer(rootReducer, initialState);
    const store = React.useMemo(() => [state, dispatch], [state]);
    const [globalState, globalDispatch] = store;


    const value = {
//gameSettingValues
        questionList: globalState.gameSetting.questionList,
        levelsList: globalState.gameSetting.levelsList,
        selectedLevel: globalState.gameSetting.selectedLevel,
        //question action
        fillQuestionList: (data) => {
            globalDispatch({type: actions.FILL_QUESTION_LIST, payload: data});
        },
        createQuestionItem: (data) => {
            globalDispatch({type: actions.CREATE_QUESTION_ITEM, payload: data});
        },
        editQuestionItem: (data) => {
            globalDispatch({type: actions.EDIT_QUESTION_ITEM, payload: data});
        },
        removeQuestionItem: (id) => {
            globalDispatch({type: actions.DELETE_QUESTION_ITEM, payload: id});
        },

        //levels action
        fillLevelsList: (data) => {
            globalDispatch({type: actions.FILL_LEVELS_LIST, payload: data});
        },
        addLevelToList: (data) => {
            globalDispatch({type: actions.ADD_LEVEL_TO_LIST, payload: data});
        },
        editLevelToList: (data) => {
            globalDispatch({type: actions.EDIT_LEVEL_TO_LIST, payload: data});
        },
        setSelectedLevel: (data) => {
            globalDispatch({type: actions.SET_SELECTED_LEVEL, payload: data});
        },
        removeLevelItem: (id) => {
            globalDispatch({type: actions.DELETE_LEVEL_TO_LIST, payload: id});
        },
//gameSettingValues

// usersAdministrationValues
        usersList: globalState.usersAdministration.usersList,
        //users action
        fillUsersList: (data) => {
            globalDispatch({type: actions.FILL_USERS_LIST, payload: data});
        },
        editUsersList: (data) => {
            globalDispatch({type: actions.EDIT_USER, payload: data});
        },
        isBlockedUser: (data) => {
            globalDispatch({type: actions.BLOCKED_USER, payload: data});
        },
        deleteUser: (data) => {
            globalDispatch({type: actions.DELETE_USER, payload: data});
        },
// usersAdministrationValues

// RewardsSettingValues
        rewardsList: globalState.rewardSetting.rewardsList,
        salesRewardList: globalState.rewardSetting.salesRewardList,
        //users action
        fillSalesRewardsList: (data) => {
            globalDispatch({type: actions.FILL_SALES_REWARD_LIST, payload: data});
        },
        fillRewardsList: (data) => {
            globalDispatch({type: actions.FILL_REWARD_LIST, payload: data});
        },
        addReward: (data) => {
            globalDispatch({type: actions.ADD_REWARD_TO_LIST, payload: data});
        },
        editRewardsList: (data) => {
            globalDispatch({type: actions.EDIT_REWARD_TO_LIST, payload: data});
        },
        deleteReward: (data) => {
            globalDispatch({type: actions.DELETE_REWARD_TO_LIST, payload: data});
        },
        decCountReward: (data) => {
            globalDispatch({type: actions.DECREMENT_COUNT_OF_REWARD, payload: data});
        },
// RewardsSettingValues

//DirectorySettingValues
        articleList: globalState.directory.articleList,
        //users action
        fillArticleList: (data) => {
            globalDispatch({type: actions.FILL_ARTICLE_LIST, payload: data});
        },
//DirectorySettingValues

//PartnersSettingValues
        partnersList: globalState.partners.partnersList,
        //users action
        fillPartnersList: (data) => {
            globalDispatch({type: actions.FILL_PARTNER_LIST, payload: data});
        },
        addPartner: (data) => {
            globalDispatch({type: actions.ADD_PARTNER_TO_LIST, payload: data});
        },
        editPartnersList: (data) => {
            globalDispatch({type: actions.EDIT_PARTNER_TO_LIST, payload: data});
        },
        deletePartner: (data) => {
            globalDispatch({type: actions.DELETE_PARTNER_TO_LIST, payload: data});
        },
//PartnersSettingValues

//SocialMediaSettingValues
        socialMediaList: globalState.socialMedia.socialMediaList,
        //users action
        fillSocialMediaList: (data) => {
            globalDispatch({type: actions.FILL_SOCIAL_MEDIA_LIST, payload: data});
        },
        addSocialMedia: (data) => {
            globalDispatch({type: actions.ADD_SOCIAL_MEDIA_TO_LIST, payload: data});
        },
        editSocialMedia: (data) => {
            globalDispatch({type: actions.EDIT_SOCIAL_MEDIA_TO_LIST, payload: data});
        },
        deleteSocialMedia: (data) => {
            globalDispatch({type: actions.DELETE_SOCIAL_MEDIA_TO_LIST, payload: data});
        },
//SocialMediaSettingValues

// RanksValues
        ranksList: globalState.ranks.ranksList,
        //users action
        fillRanksList: (data) => {
            globalDispatch({type: actions.FILL_RANKS_LIST, payload: data});
        },
        addRank: (data) => {
            globalDispatch({type: actions.ADD_RANK_TO_LIST, payload: data});
        },
//RanksValues

// UserCabinetValues
        usersInfo: globalState.userCabinet.usersInfo,
        fillUsersInfo: (data) => {
            globalDispatch({type: actions.FILL_USERS_INFO, payload: data});
        },
        editUsersInfo: (data) => {
            globalDispatch({type: actions.EDIT_USERS_INFO, payload: data});
        },
// UserCabinetValues


// Auth
        isAdmin: globalState.auth.isAdmin,
        isUser: globalState.auth.isUser,
        loginUser: (data) => {
            globalDispatch({type: actions.LOGIN_USER, payload: data});
        },
        logoutUser: (data) => {
            globalDispatch({type: actions.LOGOUT_USER});
        },
        loginAdmin: (data) => {
            globalDispatch({type: actions.LOGIN_ADMIN, payload: data});
        },
        logoutAdmin: () => {
            globalDispatch({type: actions.REMOVE_USERS_INFO});
            globalDispatch({type: actions.LOGOUT_ADMIN});
        },
// Auth
    };


    return (
        <StoreContext.Provider value={value}>
            {children}
        </StoreContext.Provider>
    )
}

export default StoreContext