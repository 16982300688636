import React from "react"
import './App.css';
import Layout from "antd/es/layout/layout";
import useRoutes from "./routes";
import {BrowserRouter as Router} from "react-router-dom";
import GameSettingContext from "./context/GameSettingContext";
import {ConfigProvider} from "antd";



function App() {
    const {isUser, isAdmin} = React.useContext(GameSettingContext);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorTextBase: '#d0d0d0',
                    colorBgBase: "#212123",
                    colorPrimary: '#ff8400',
                },
            }}
        >
                <Layout style={{minHeight: "100vh"}}>
                    <Router>
                            {useRoutes(isUser, isAdmin)}
                    </Router>
                </Layout>
        </ConfigProvider>
    );
}

export default App;
