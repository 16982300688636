import React from "react"
import {
    Form,
    Input,
    Modal,
    notification,
} from "antd";
import st from './CreateLevelModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";


function CreateLevelModal({isModalOpen, setIsModalOpen, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {loading, error, request, clearError} = useHttp()
    const {addLevelToList} = React.useContext(GameSettingContext);
    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const body = JSON.stringify(values)
            const data = await request(`${baseUrl}/api/lvl`, "POST", body, {'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            addLevelToList(data)
            clearError()
            setIsModalOpen(false)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (

        <Modal title="Створення нового рівня"
               okText="Створити"
               cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} centered>

            <Form className={st.card} form={form} onFinish={onFinish}
                  autoComplete="off">
                <Form.Item name={"name"} rules={[
                    {
                        required: true,
                        message: 'Введіть назву рівня',
                    },
                    {
                        max: 128,
                        message: 'Максимальна кількість символів 128',
                    }
                ]}>
                    <Input placeholder="Введіть назву"/>
                </Form.Item>
            </Form>
        </Modal>

    );
}


export default CreateLevelModal;
