import React, {useCallback, useEffect, useState} from "react"
import {Card, Col, FloatButton, Image, List, notification, Row, Typography} from "antd";
import st from "./AdministrationPage.module.css"
import {Content} from "antd/es/layout/layout";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import Preloader from "../../../components/Preloader/Preloader";
import Sidbar from "../../../components/sider/Sider";
import {useDocumentTitle} from "../../../hooks/title.hook";
import defaultPicture from "../../../images/defailt_picture.jpg";
import AdministrationModal from "../../../components/Modals/AdministrationModal/AdministrationModal";
import {MailOutlined} from "@ant-design/icons";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";





function AdministrationPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Адміністрування користувачів — Воби2");
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {fillUsersList, editUsersList,deleteUser,  usersList, isBlockedUser} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const [isAlertsModalOpen, toggleAlertsModalOpen] = useState(false)

    const fetchUserList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/users`, "GET", null, {authorization: `Bearer ${token?.accessToken}`})
            fillUsersList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    const onBlockUser = useCallback(async (id) => {
        try {
            await request(`${baseUrl}/api/user/blocked/${id}`, "GET", null, {authorization: `Bearer ${token?.accessToken}`})
            isBlockedUser(id)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    console.log(usersList)


    const ondDeleteUser = useCallback(async (id) => {
        try {
            const data = await request(`${baseUrl}/api/user/delete/${id}`, "DELETE", null, {authorization: `Bearer ${token?.accessToken}`})
            deleteUser(data.id)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])


    useEffect(() => {
        fetchUserList()
    }, [fetchUserList])






    if (loading) {
        return <Preloader/>
    }
    return ( <>
        <Sidbar/>

        <Content className={st.adminUsers}>
                <Row className={st.title}>
                    <Col span={20}><Typography.Title>Адміністрування користувачів</Typography.Title></Col>
                    <Col span={4} className={st.alertsAddBlock}><span className={st.alertsAdd}
                                                                   onClick={() => toggleAlertsModalOpen(true)}>Оповіщення користувачів <MailOutlined /></span>
                    </Col>
                </Row>
            <Row>
                <Col span={24}>
                    <List
                        grid={{
                            gutter: 8,
                            xs:1,
                            sm:1,
                            md:1,
                            lg: 2,
                            xl:2,
                            xxl:4
                        }}
                        dataSource={usersList}
                        renderItem={(item) => (
                            <List.Item>
                                <div className={st.cardPosition}>
                                    <Card
                                          className={st.card}
                                          actions={[
                                             <span key={"unblocked"} onClick={()=>onBlockUser(item?._id)}>{item?.isBlocked ? "Розблокувати" : "Заблокувати"}</span>,
                                             <span key={"delete"} onClick={()=>ondDeleteUser(item?._id)}>{"Видалити"}</span>
                                          ]}
                                          >
                                        <Card.Meta
                                            avatar={!!item?.rank[0]?.images[0]
                                            ? <Image
                                            style={{
                                                height: 70,
                                                borderRadius: 6,
                                            }}
                                            src={`${baseUrl}${item?.rank[0]?.images[0]}`}
                                        />
                                            :  <Image
                                            style={{
                                                width: 70,
                                                height: 70,
                                                borderRadius: 6,
                                            }}
                                            src={defaultPicture}
                                        />
                                            }
                                            title={item?.tg_username}
                                            description={<div className={st.description}>
                                                <Typography.Text>{`Ім'я: ${item?.name}`}</Typography.Text>
                                                <Typography.Text>{`Вік: ${item?.age}`}</Typography.Text>
                                                <Typography.Text>{`Сумарно балів: ${item?.rank_points}`}</Typography.Text>
                                            </div>}

                                        />


                                    </Card>
                                </div>

                            </List.Item>
                        )}
                    />
                </Col></Row>

                <FloatButton onClick={() => toggleAlertsModalOpen(true)} tooltip={<div>Оповіщення користувачів</div>}
                             icon={<MailOutlined />}/>

            <AdministrationModal isModalOpen={isAlertsModalOpen} setIsModalOpen={toggleAlertsModalOpen} usersItems={usersList}/>
        </Content>
        </>
    );
}

export default AdministrationPage;
