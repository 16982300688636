import React from "react"
import {
    Form,
    Input,
    Modal,
    notification, Select,
} from "antd";
import st from './addInfoModal.module.css'
import TextArea from "antd/es/input/TextArea";
import {useHttp} from "../../../hooks/http.hook";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";



function AddInfoModal({isModalOpen, setIsModalOpen,initValue,userStorage, ...props}) {

    const {loading, error, request, clearError} = useHttp()
    const {editUsersInfo} = React.useContext(GameSettingContext);
    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            console.log(values)

            const  body = JSON.stringify(values)
            console.log(body)
            const data = await request(`${baseUrl}/api/user`, "PUT", body,{
                authorization: `Bearer ${userStorage?.accessToken}`,
                'Content-Type': "application/json"
            })
            editUsersInfo(data[0])
            clearError()
            setIsModalOpen(false)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };




    return (

        <Modal title="Змінити інформацію Користувача"
               okText="Змінити"
               cancelText="Скасувати" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={500} centered>
            <Form
                form={form}
                className={st.card}
                onFinish={onFinish}
                initialValues={initValue}
                autoComplete="off"
            >
                <Form.Item
                    name={"email"}
                    rules={[
                        {
                            type: "email",
                            message: "Некоректний email",
                        },
                    ]}
                >
                    <Input placeholder="Введіть email"/>
                </Form.Item>
                <Form.Item
                    name="gender"
                >
                    <Select
                        placeholder="Виберіь стать"
                        onChange={() => {
                        }}
                        allowClear
                    >
                        <Select.Option value="Чоловіча">Чоловіча</Select.Option>
                        <Select.Option value="Жіноча">Жіноча</Select.Option>
                        <Select.Option value="Інша">Інша</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={"about_me"}

                >
                    <TextArea rows={4} placeholder="Тут ти можешь написати будь-що про себе"/>
                </Form.Item>
            </Form>
        </Modal>

    );
}


export default AddInfoModal;
