import React, {useCallback, useEffect, useState} from "react"
import st from "./Rewards.module.css"
import {Content} from "antd/es/layout/layout";
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import { Button, Card, Col, Image, List, notification, Row} from "antd";
import {useDocumentTitle} from "../../../hooks/title.hook";
import BuyRewardModal from "../../../components/Modals/rewardModal/buyRewards/BuyRewardModal";
import defaultPicture from  "../../../images/defailt_picture.jpg"
import Preloader from "../../../components/Preloader/Preloader";
import {STORAGE_ADMIN} from "../../../context/Reducers/AuthReducer";

function RewardsPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Нагороди — Воби2");
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {rewardsList, fillRewardsList, decCountReward} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const [isOpenModal, toggleOpenModal] = useState({})

    const fetchRewardsList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/rewards`, "GET", null, {})
            fillRewardsList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchRewardsList()
    }, [fetchRewardsList])

    const handleBuyReward = async (item) => {
        if (item.type === "physical") {
            toggleOpenModal(item)
        } else if (item.type === "digital") {
            try {
                const data = await request(`${baseUrl}/api/rewards/buy/${item._id}`, "POST", null, {
                    'Content-Type': "application/json",
                    authorization: `Bearer ${token?.accessToken}`
                })
                decCountReward(item._id)
                notification['success']({
                    message: data
                });
                clearError()
            } catch (e) {
                notification['error']({
                    message: e.message
                });
            }
        }
    }

    return (
        <div className={clsx(st.grIndex)}>
            <LandingHeader/>
            <Content className={clsx(st.main)}>
                <Row className={st.lvlList}>
                    <Col span={24}>
                        {!loading ? <List
                            grid={{
                                gutter: 8,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 2,
                                xl: 2,
                                xxl: 4
                            }}
                            dataSource={rewardsList}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className={st.cardPosition}>
                                        <Card
                                            extra={<div className={st.extraBlock}>
                                                <p className={st.extra}>{`Тип: ${item.type === 'digital' ? "цифровий" : "фізичний"}`}</p>
                                                <p className={st.extra}>{`Кількість: ${item.count}`}</p>
                                                <p className={st.extra}>{`Вартість: ${item.cost}`}</p>
                                            </div>}
                                            actions={!!token?.user && [
                                                <Button type={"primary"}
                                                        onClick={() => handleBuyReward(item)}>Отримати</Button>,
                                            ]}
                                            className={st.lvlCard}
                                        >
                                            <Card.Meta
                                                avatar={item.images[0] ? <Image
                                                    src={`${baseUrl}${item.images[0]}`}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 6,
                                                    }}/> : <Image
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 6
                                                    }}
                                                    alt="logo"
                                                    src={defaultPicture}
                                                />}
                                                title={item.name}
                                                description={item.description}
                                            />

                                        </Card>
                                    </div>

                                </List.Item>
                            )}
                        /> : <Preloader/>}

                    </Col></Row>

                {Object.keys(isOpenModal).length > 0 &&  <BuyRewardModal isModalOpen={isOpenModal} setIsModalOpen={toggleOpenModal}/>}

            </Content>
            <LandingFooter/>
        </div>
    );
}


export default RewardsPage;
