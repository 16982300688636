import {Checkbox, Divider} from "antd";
import {useState} from "react";
import st from "./SelectFieldsStep.module.css"
const CheckboxGroup = Checkbox.Group;

function SelectFieldsStep({plainOptions,checkedList, setCheckedList}) {

    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const onChange = (list) => {

        if (list.length === 1 && list[0]=== 'button'){
           list = []
        }
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? plainOptions.map(el=> el.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };



    return (
        <div className={st.checkboxMain}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Вибрати все
            </Checkbox>
            <Divider />
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </div>
    );
}

export default SelectFieldsStep;
