import React, {useCallback, useEffect, useState} from "react"
import st from "../LandingPage.module.css"
import {Content} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import list1 from "../../../images/index/i-list1.png"
import list2 from "../../../images/index/i-list2.gif"
import list3 from "../../../images/index/i-list3.png"
import list4 from "../../../images/index/i-list4.png"
import file1 from "../../../files/file1.pdf"
import clsx from "clsx";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import {useDocumentTitle} from "../../../hooks/title.hook";
import draftToHtml from "draftjs-to-html";
import {useHttp} from "../../../hooks/http.hook";
import {EditorState} from "draft-js";
import {baseUrl} from "../../../config";
import {notification} from "antd";
import Preloader from "../../../components/Preloader/Preloader";

function LandingPage() {
    const {loading, error, request, clearError} = useHttp()
    const [info, setInfo] = React.useState(EditorState.createEmpty());
    const [document_title, setDoucmentTitle] = useDocumentTitle("Воби2 — чат-бот гра про правила поведінки у воєнний час");
    const navigate = useNavigate()
    const fetchInfo = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/block/indexPage`, "GET", null, {})
            setInfo(data)
            data.title && setDoucmentTitle(data.title)
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {

        fetchInfo()

    }, [fetchInfo])

    if (loading) {

        return <Preloader/>
    }

    return (
        <div className={clsx(st.grIndex)}>
            <LandingHeader />
        <Content className={clsx(st.landing, st.grsOne)}>
            <section className={clsx(st.grStd, st.iCover)}>
                <h2 className={st.iSlogan}><span>Перевір, що&nbsp;ти&nbsp;знаєш про правила поведінки у&nbsp;воєнний час!</span></h2>
            </section>

            <main  className={clsx(st.bBasic, st.landing)} dangerouslySetInnerHTML={{__html: info.main_block && draftToHtml(JSON.parse(info?.main_block))}}/>

            {/*<ul className={st.grIlist}>*/}
            {/*    <li>*/}
            {/*        <div>*/}
            {/*            <img src={list1} alt="" />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3 className={st.cOrange} >Перевір, що&nbsp;ти&nbsp;знаєш про безпеку у&nbsp;воєнний час</h3>*/}
            {/*            <p className={st.p}>Відповідай на&nbsp;питання та&nbsp;прокачуй свої знання!</p>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li className={st.iListEven}>*/}
            {/*        <div>*/}
            {/*            <img src={list2} alt="" />*/}
            {/*        </div>*/}
            {/*        <div className={st.iListText}>*/}
            {/*            <h3 className={st.cOrange}>Грай разом з&nbsp;Воби2, нашим експертом із&nbsp;безпеки</h3>*/}
            {/*            <p className={st.p}>Він підкаже, як&nbsp;не&nbsp;потрапити в&nbsp;скрутну ситуацію, а, раптом&nbsp;що,&nbsp;&mdash; як&nbsp;з&nbsp;неї вибратися.</p>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <div>*/}
            {/*            <img src={list3} alt="" />*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3 className={st.cOrange}>Шукай правильні відповіді</h3>*/}
            {/*            <p className={st.p}>На&nbsp;кожне питання є&nbsp;дві, три або чотири відповіді. Твоє завдання&nbsp;&mdash; добре подумати й&nbsp;обрати правильну.</p>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li className={st.iListEven}>*/}
            {/*        <div>*/}
            {/*            <img src={list4} alt="" />*/}
            {/*        </div>*/}
            {/*        <div className={st.iListText}>*/}
            {/*            <h3 className={st.cOrange}>Виграй один з&nbsp;п&rsquo;яти смартфонів</h3>*/}
            {/*            <p className={st.p}>Серед всіх, хто до&nbsp;15-го грудня, включно, пройде тест і&nbsp;дасть правильні відповіді на&nbsp;питання всіх трьох рівнів ми&nbsp;розіграємо 5&nbsp;смартфонів. Переможців визначимо за&nbsp;допомогою сайту <a href="https://www.random.org" target="_blank">random.org</a>.</p>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*</ul>*/}
            {/*<p className={clsx(st.grLcBig, st.pUp96)}>Пам&rsquo;ятай, що&nbsp;ти&nbsp;можеш проходити рівні безліч разів і&nbsp;таким чином засвоювати матеріал. Тобто доклавши трохи зусиль тобі точно вдасться пройти тест без жодної помилки.</p>*/}
            {/*<p className={clsx(st.grLcBig, st.pId32)}>Більше про <a href={file1} target="_blank">правила участі в&nbsp;розіграші</a>.</p>*/}
            {/*<p className={st.grLcBig}>Не&nbsp;зволікай!</p>*/}
            <div style={{marginTop:30}} className={clsx(st.grLc, st.pDBlock, st.grCs2)} >
                <button onClick={()=>document.location='https://t.me/voby2_bot'} className="">Розпочни гру прямо зараз!</button>
            </div>
        </Content>
            <LandingFooter />
        </div>
    );
}


export default LandingPage;
