import React, {useContext, useEffect, useState} from "react"
import st from './AuthPage.module.css'
import {Content} from "antd/es/layout/layout";
import {Button, Col, Form, Input, Row, message, notification} from "antd";
import {useHttp} from "../../../hooks/http.hook";
import { Typography } from 'antd';
import {baseUrl} from "../../../config";
import {useDocumentTitle} from "../../../hooks/title.hook";
import GameSettingContext from "../../../context/GameSettingContext";

const { Title } = Typography;

function AuthPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Авторизація адміністратора — Воби2");
    const {loginAdmin} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const [value, setValue] = useState({
        login: "",
        password: ""
    })

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message
        });
    };


    const changeHandler = event => {
        setValue({...value, [event.target.name]: event.target.value})
    }
    const isLogin = async () => {
        try {
            const body = JSON.stringify({...value})
            const data = await request(`${baseUrl}/api/admin/login`, 'POST', body,  {'Content-Type': "application/json"})
            loginAdmin(data)
        } catch (e) {
        }
    }

    useEffect(() => {
        error && openNotificationWithIcon('error', error)
        clearError()
    }, [error, clearError])


    return (
        <Content className={st.loginContainer}>
            <Row justify={"center"} align={"middle"} >
                <Col span={12} className={st.signInForm}>
                    <Title>Панель адміністратора</Title>
                    <div className={st.login}>
                        <Input
                            placeholder="Логин"
                            name={"login"}
                            id={"login"}
                            value={value.login}
                            onChange={changeHandler}
                        />
                    </div>
                    <div>
                        <Input.Password placeholder="Пароль"
                                        name={"password"}
                                        id={"password"}
                                        value={value.password}
                                        onChange={changeHandler}
                        />
                    </div>
                    <Button type={"primary"} shape="round" disabled={loading} onClick={isLogin}>Увійти</Button>
                </Col>
            </Row>
        </Content>
    );
}

export default AuthPage;
