import {actions} from "./actionTypes";

export const SocialMediaInitialState = {
    socialMediaList: [],
};



export const SocialMediaReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_SOCIAL_MEDIA_LIST:
            return {
                ...state,
                socialMediaList: [...action.payload]
            };
        case actions.ADD_SOCIAL_MEDIA_TO_LIST:
            return {
                ...state,
                socialMediaList: [...state.socialMediaList, action.payload]
            };
        case actions.EDIT_SOCIAL_MEDIA_TO_LIST:
            const updatedList = state.socialMediaList.map((item) =>
                item._id === action.payload._id
                    ? {...action.payload}
                    : item
            );
            return {
                ...state,
                socialMediaList: updatedList
            };

        case actions.DELETE_SOCIAL_MEDIA_TO_LIST:
            const filteredList = state.socialMediaList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                socialMediaList: filteredList
            };
        default:
            return state;
    }
};