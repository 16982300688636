import {actions} from "./actionTypes";

export const STORAGE_USER = 'storageUser';
export const STORAGE_ADMIN = 'storageAdmin'

export const AuthReducerInitialState = {
    isAdmin: !!JSON.parse(localStorage.getItem(STORAGE_ADMIN)),
    isUser: !!JSON.parse(localStorage.getItem(STORAGE_USER)),
};


export const AuthReducerReducer = (state, action) => {
    switch (action.type) {
        case actions.LOGIN_USER:
            localStorage.setItem(STORAGE_USER, JSON.stringify({
                info: action.payload.info,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            }));
            return {
                ...state,
                isUser: true
            };

        case actions.LOGOUT_USER:
            localStorage.removeItem(STORAGE_USER);
            return {
                ...state,
                isUser: false
            };
        case actions.LOGIN_ADMIN:
            localStorage.setItem(STORAGE_ADMIN, JSON.stringify({
                info: action.payload.info,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            }));
            return {
                ...state,
                isAdmin: true
            };
        case actions.LOGOUT_ADMIN:
            localStorage.removeItem(STORAGE_ADMIN);
            return {
                ...state,
                isAdmin: false
            };
        default:
            return state;
    }
};