import React, {useCallback, useEffect, useState} from "react"
import st from "../../../page/LandingPage/LandingPage.module.css"
import {NavLink} from "react-router-dom";
import clsx from "clsx";
import {baseUrl} from "../../../config";
import {Avatar, Image, notification, Tooltip} from "antd";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import {LinkOutlined} from "@ant-design/icons";
import defaultPicture from  "../../../images/defailt_picture.jpg"

function LandingFooter() {
    const {fillPartnersList, partnersList, fillSocialMediaList, socialMediaList} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()

    const fetchSocialMediaList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/socialMedia`, "GET", null, {})
            fillSocialMediaList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchSocialMediaList()
    }, [fetchSocialMediaList])

    const fetchPartnersList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/partners`, "GET", null, {})
            fillPartnersList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchPartnersList()
    }, [fetchPartnersList])


    const partners = partnersList.map(el =>

        <li key={el._id} >
            <a href={el.link} target="_blank" className={clsx(st.pOp5h, st.lNo)}>

                <Tooltip color={"#ff8400"} key={"white"} title={el.name}>
                    {el.images[0] ?
                        <Image
                            preview={false}
                            src={`${baseUrl}${el.images[0]}`}
                            style={{
                                width: 70,
                                height: 70,
                                borderRadius: 6,
                            }}/> : <Image
                            preview={false}
                            style={{
                                width: 70,
                                height: 70,
                                borderRadius: 6
                            }}
                            alt="logo"
                            src={defaultPicture}
                        />}
                </Tooltip>

            </a>
        </li>
    )
    const socialMedia = socialMediaList.map(el =>

        <li key={el._id} style={{marginRight: "8px"}}>
            <a href={el.link} className={st.lNo} target="_blank">
                <Tooltip color={"#ff8400"} key={"white"} title={el.title}>
                    {el.images[0] ?
                        <Avatar src={`${baseUrl}${el.images[0]}`}/>
                        : <Avatar icon={<LinkOutlined />}/>}
                </Tooltip>
            </a></li>
    )

    // const navigate = useNavigate()


    return (
        <footer className={clsx(st.footer, st.pUp128)}>
            <ul className={clsx(st.fMenu, st.tSmall)}>
                <li>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 172 54" enableBackground="new 0 0 172 54">
                        <path
                            d="m159.48 27.675c-3.731-.122-6.727.041-10.681.924 5.131-3.196 7.655-5.678 9.137-8.427 2.999-5.56 1.374-11.02-.642-13.948-2.6-3.782-7.504-5.678-14.468-5.678-7.823 0-20.721 2.313-29.483 9.295l4.04 3.346c-3.084 1.914-8.509 3.829-11.692 3.91l-.083 1.19c-.636 11.283.007 17.271.657 19.521-1.043.092-3.382.133-3.895-2.268-.72-3.37-1.181-11.674.41-21.14-3.13 1.833-9.533 3.591-13.247 3.604l-.171 1.089c-.02.181-.038.362-.057.544-2.053-2.217-4.777-3.546-7.689-3.951l6.269-14.326-2.391-.116c-6.744 0-12.429 2.463-16.548 6.889-2.656 2.854-4.573 6.424-5.7 10.612-2.759-3.501-6.83-5.522-11.442-5.522-4.611 0-8.316 2.026-10.913 5.222.008-.14.013-.28.014-.417.04-2.996-1.574-10.02-17.402-10.584-.753-.027-1.534-.04-2.322-.04-6.784 0-14.12.985-19.16 2.571l-2.023.637 9.824 12.97c0 0 1.789-.331 2.047-.384l.067 3.632c-.033-.001-1.745-.021-1.745-.021l.042 6.443-4.926-2.092.328 10.672c5.733 2.914 10.44 7.621 21.246 7.621 8.181 0 12.166-2.844 13.968-6.227 2.576 2.92 6.275 4.804 10.941 4.804 5.173 0 10.115-3.037 12.883-8.161 2.759 4.962 7.474 8.161 12.963 8.161 5.735 0 10.316-3.088 12.936-7.88 1.83 4.256 6.25 7.196 12.549 7.196 3.419 0 6.11-.451 8.573-1.518 1.235.809 2.7 1.376 4.224 1.709l3.642 5.918c15.02-8 37.17-8 52.44-5.255l-12.518-20.523m-134.39 9.89l-.072-2.9c1.591.198 2.729.579 3.536 1.388-.184.892-1.262 1.635-3.464 1.512m28.09-.062c-1.996-.231-3.391-2.293-3.391-4.963 0-2.476 1.77-4.324 3.569-4.324 2.396 0 3.724 2.039 3.813 3.917.165 3.479-1.888 5.613-3.991 5.37m23.23-1.945c-1.892 0-3.552-2.149-3.552-4.759 0-3.606 1.968-5.182 3.727-5.182 2.345 0 3.727 2.175 3.727 4.833-.001 3.041-1.642 5.108-3.902 5.108m42.45.125c-.468-6.304-.772-14.789-.409-20.2l.097-1.331 14.07 11.661c-4.578 2.887-9.52 6.346-13.753 9.871"
                            fill="#180828"/>
                        <path
                            d="m158.57 29.31c-7.413 0-17.494 2.076-23.425 4.151 4.893-4 14.945-7.864 19.866-13.343 3.16-3.518 3.139-9.411.741-12.898-6.523-9.488-30.772-3.262-39.519 2.816l14.465 11.861c2.52-1.334 5.03-2.899 8.889-3.156 4.448-.297 5.05 2.563 1.786 4.342-6.317 3.445-15.465 9.237-22.31 15.14.277 2.985 1.795 6.289 4.844 8.145.357.217.453.403-.179.675-1.468.63-3.29.908-5.164.834l1.164 1.891c16.308-8.01 36.17-6.82 48.03-5.337l-9.193-15.12"
                            fill="#ff8400"/>
                        <path
                            d="m120.91 46.19c-4.261.211-8.2-1.802-9.322-3.084-5.714 2.942-18.14 2.674-19.86-5.523.824-2.27 1.271-4.805 1.271-7.502 0-3.247-.745-5.965-1.998-8.138.058-.895.133-1.78.227-2.643 2.353-.164 6.374-.983 8.987-2.157-.985 4.898-.841 15.33.098 18.631.848 2.983 4.214 4.378 8.237 3.065-1.519-3.571-1.56-11.698-1.067-20.441 2.627-.493 6.075-1.314 9.09-3.037-.441 6.568-.113 16.852.624 23.06.399 3.359 1.636 6.076 3.79 7.59.077.052.028.17-.075.175m-29.835-16.562c0 9.588-6.309 15.831-13.688 15.831-5.223 0-9.297-3.675-11.433-8.582.675-2.063 1.039-4.361.999-6.86-.054-3.428-.849-6.459-2.2-8.97 2.069-11.696 10.198-17.947 20.801-17.923l-3.941 8.814c-3.282.055-8.7 2.271-9.912 7.58 4.972-5.373 19.374-3.831 19.374 10.11m-8.886-.058c0-4.39-2.501-6.891-5.768-6.891-3.522 0-5.615 3.369-5.615 7.248 0 3.573 2.348 6.891 5.513 6.891 3.164 0 5.87-2.858 5.87-7.248m-17.11.058c0 9.588-6.309 15.831-13.688 15.831-3.869 0-7.212-1.994-9.52-5.01.223-1 .311-2 .311-2.94 0-3.099-1.56-5.833-3.629-7.955.162-4.145 1.502-7.966 3.834-10.71l4.775 2.933-1.509-5.665c1.72-.979 3.722-1.533 5.975-1.533 7.619.001 13.451 5.464 13.451 15.05m-6.02 2.508c0-4.39-2.501-6.891-5.768-6.891-3.522 0-5.615 3.369-5.615 7.248 0 3.573 2.348 6.891 5.513 6.891 3.164.001 5.87-2.858 5.87-7.248m-18.987 5.186c0 5.135-4.279 8.681-12.961 8.742-8.681.061-13.633-3.485-20.17-7.336l-.245-5.441c1.762.807 4.054 1.791 6.596 2.713l-.082-5.802c-.704.062-1.409.134-2.113.216v-2.812c.686.026 1.381.05 2.077.07l-.091-6.454c-.971.156-1.98.347-3.03.576l-7.457-10.03c5.624-1.773 13.939-2.69 20.847-2.445 11.133.393 15.65 4.157 15.589 8.68-.046 3.423-2.14 7.336-7.153 9.231 3.301 1.407 8.192 4.953 8.192 10.09m-17.05-16.343l.236 6.418c2.926-.39 4.89-1.181 4.833-2.669-.071-1.848-1.89-3.183-5.069-3.749m.34 9.246l.311 8.463c3.17.316 6.328-.164 6.558-3.383.213-2.994-2.663-4.505-6.869-5.08"
                            fill="#eceff1"/>
                    </svg>
                </li>
                <li><NavLink to="/about">Про гру</NavLink></li>
                <li><NavLink to="/directory">Довідник</NavLink></li>
                <li><a href="https://t.me/voby2_bot" target="_blank">Почати гру</a></li>
            </ul>
            <div className={clsx(st.fContact, st.cGray)}>
                <p className={st.tSmall}>+380 99 111 8928<br/>
                    <a href="mailto:hello@voby2.com.ua">hello@voby2.com.ua</a><br/>
                    <a href="https://t.me/dartsidevoby2" target="_blank">@darksidevoby2</a></p>
                <ul className={st.fSocial}>
                    {/*<li><a href="https://t.me/voby2_bot" className={st.lNo} target="_blank">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                    {/*         enableBackground="new 0 0 24 24">*/}
                    {/*        <path id="img-ico-tm"*/}
                    {/*              d="m12 0c-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12s12-5.373 12-12c0-6.628-5.373-12-12-12m6.03 7.154l-2.22 10.451c-.162.758-.542.975-1.245.596l-3.357-2.491-1.625 1.57c-.271.217-.487.325-.704.325l.271-3.466 6.281-5.739c.108-.108.108-.163.054-.217-.108-.054-.271-.054-.487.108l-7.743 4.874-3.357-1.029c-.271-.054-.433-.163-.487-.271-.054-.108-.054-.217.054-.379.108-.162.325-.271.596-.379l13.05-5.04c.325-.108.542-.108.758.108s.269.542.161.975"*/}
                    {/*              fill="#909090"/>*/}
                    {/*    </svg>*/}
                    {/*</a></li>*/}
                    {/*<li><a href="https://www.facebook.com/EngineeringWeekUA" className={st.lNo} target="_blank">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                    {/*         enableBackground="new 0 0 24 24">*/}
                    {/*        <path id="img-ico-fb"*/}
                    {/*              d="m10.306 18.528h2.698v-6.529h1.8l.238-2.25h-2.038l.003-1.126c0-.586.056-.901.898-.901h1.125v-2.25h-1.8c-2.162 0-2.923 1.092-2.923 2.927v1.351h-1.349v2.25h1.348v6.528m1.694 5.472c-6.627 0-12-5.373-12-12 0-6.628 5.373-12 12-12s12 5.372 12 12c0 6.627-5.373 12-12 12"*/}
                    {/*              fill="#909090"/>*/}
                    {/*    </svg>*/}
                    {/*</a></li>*/}
                    {/*<li><a href="" className={st.lNo} target="_blank">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">*/}
                    {/*        <path id="img-ico-yt"*/}
                    {/*              d="m12 0c-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12s12-5.373 12-12c0-6.628-5.373-12-12-12m6.987 16.515c-.722.819-1.408.885-6.986.885s-6.264-.066-6.986-.885c-.591-.672-.692-1.997-.692-4.515 0-2.518.101-3.843.694-4.515.721-.819 1.407-.885 6.985-.885s6.264.066 6.986.885c.592.672.693 1.997.693 4.515 0 2.518-.101 3.843-.694 4.515m-4.228-4.708c.214.112.213.293 0 .404l-4.442 2.302c-.214.111-.389.004-.389-.237l-.002-4.553c0-.241.175-.347.388-.235l4.445 2.319"*/}
                    {/*              fill="#909090"/>*/}
                    {/*    </svg>*/}
                    {/*</a></li>*/}
                    {/*<li><a href="" className={st.lNo} target="_blank">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">*/}
                    {/*        <path id="img-ico-in"*/}
                    {/*              d="m15.1 5.972h-6.146c-.895 0-1.671.269-2.178.776-.538.537-.806 1.283-.806 2.179v6.117c0 .925.269 1.671.806 2.208.537.507 1.283.776 2.178.776h6.087c.895 0 1.641-.269 2.178-.776.537-.507.865-1.253.865-2.178v-6.147c0-.865-.298-1.611-.806-2.148-.537-.538-1.283-.807-2.178-.807m-3.103 9.818c-2.119 0-3.819-1.701-3.819-3.819 0-2.119 1.731-3.82 3.819-3.82s3.819 1.731 3.819 3.82c0 2.088-1.7 3.819-3.819 3.819m3.969-6.863c-.478 0-.865-.387-.865-.865 0-.478.387-.865.865-.865.478 0 .865.387.865.865 0 .477-.387.865-.865.865m-3.969-8.927c-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12s12-5.373 12-12c0-6.628-5.373-12-12-12m7.4 15.07c0 1.283-.418 2.387-1.223 3.163-.776.746-1.85 1.164-3.133 1.164h-6.087c-1.223 0-2.298-.388-3.103-1.164-.836-.805-1.254-1.909-1.254-3.193v-6.116c0-2.596 1.731-4.327 4.327-4.327h6.147c1.253 0 2.357.418 3.133 1.223.776.776 1.193 1.85 1.193 3.103v6.147m-4.953-3.103c0 1.343-1.104 2.447-2.447 2.447s-2.447-1.104-2.447-2.447c0-1.343 1.104-2.447 2.447-2.447s2.447 1.104 2.447 2.447"*/}
                    {/*              fill="#909090"/>*/}
                    {/*    </svg>*/}
                    {/*</a></li>*/}
                    {socialMedia}
                </ul>
            </div>
            <div className={clsx(st.fPartners, st.tXsmall, st.cGray)}>
                <p>Медійна підтримка:</p>
                <ul className={st.fPlogo}>


                    {partners.length > 0 ? partners : <span style={{color: "#909090"}}>Відсутня</span>}
                </ul>
            </div>
            <ul className={clsx(st.fSecmenu, st.tXsmall, st.cGray)}>
                <li><NavLink to={"/imprint"}>Вихідні дані</NavLink></li>
                <li><NavLink to={"/policy"}>Політика&nbsp;конфіденційності</NavLink></li>
                <li><NavLink to={"/terms"}>Умови&nbsp;користування</NavLink></li>
            </ul>
            <p className={clsx(st.fCopyright, st.tXsmall, st.cGray)}>&copy;&nbsp;ГО&nbsp;&laquo;Про.Про.Лаб&raquo;,
                2022</p>


        </footer>

    )
}

export default LandingFooter;
