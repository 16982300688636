import React from "react"
import {
    Form,
    Input,
    Modal,
    notification,
} from "antd";
import st from './EditLevelModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import GameSettingContext from "../../../../context/GameSettingContext";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";





function EditLevelModal({isModalOpen,setIsModalOpen, lvl, ...props}) {
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))
    const {request, clearError} = useHttp()
    const {editLevelToList} = React.useContext(GameSettingContext);
    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                form.resetFields();
                onFinish(values);
            })
            .catch((info) => {
                console.log(info);
            });
    };
    const handleCancel = () => {
        setIsModalOpen({});
    };

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const body = JSON.stringify(values)
        try {
            const data = await request(`${baseUrl}/api/lvl/${isModalOpen._id}`, "PUT", body,{'Content-Type': "application/json", authorization: `Bearer ${token?.accessToken}`})
            editLevelToList(data)
            clearError()
            setIsModalOpen({})
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };





    return (

                <Modal title={`Редагування ${isModalOpen.name}`}
                       okText="Зберегти"
                       cancelText="Скасувати" open={Object.keys(isModalOpen).length > 0} onOk={handleOk} onCancel={handleCancel} width={500} centered>

                    <Form className={st.card} form={form} onFinish={onFinish}
                          autoComplete="off">
                        <Form.Item name={"name"} rules={[
                            {
                                required: true,
                                message: 'Введіть назву рівня',
                            },
                            {
                                max: 128,
                                message: 'Максимальна кількість символів 128',
                            },
                        ]}>
                            <Input placeholder="Введіть нову назву"/>
                        </Form.Item>
                    </Form>
                </Modal>

    );
}


export default EditLevelModal;
