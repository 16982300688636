import React from "react"
import {Drawer, notification, Space, Tag} from "antd";
import st from './TagsModal.module.css'
import {baseUrl} from "../../../../config";
import {useHttp} from "../../../../hooks/http.hook";
import {DeleteOutlined} from "@ant-design/icons";
import {STORAGE_ADMIN} from "../../../../context/Reducers/AuthReducer";



function TagsModal({isModalOpen, setIsModalOpen, tags, setTags,  ...props}) {
    const {loading, error, request, clearError} = useHttp()
    const token = JSON.parse(localStorage.getItem(STORAGE_ADMIN))


    const showTags = tags?.map(el=><Tag onClick={()=>onDeleteTag(el._id)} key={el._id} color="#ff8400" className={st.tags}>{el.name} <DeleteOutlined /></Tag>)

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const onDeleteTag = async (id) => {
        try {
             await request(`${baseUrl}/api/tags/${id}`, "DELETE", null, {authorization: `Bearer ${token?.accessToken}`})
            const data = await request(`${baseUrl}/api/tags`, "GET", null, {})
            setTags(data)
            clearError()

            notification['success']({
                message: "Тег видаленно"
            });
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    };


    return (
        <Drawer title="Видалення тегів"
                open={isModalOpen}
                onClose={handleCancel}
                size={'large'}>
            <Space size={[16, 16]} wrap>
                {showTags}
            </Space>

        </Drawer>

    );
}


export default TagsModal;
