import React, {useCallback, useEffect, useState} from "react"
import {
    Button,
    Col,
    Collapse,
    Image,
    notification,
    Row,
    Typography
} from "antd";
import st from "./UserProfilePage.module.css"
import {Content} from "antd/es/layout/layout";
import {CaretRightOutlined} from "@ant-design/icons";
import GameSettingContext from "../../../context/GameSettingContext";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import Preloader from "../../../components/Preloader/Preloader";
import LandingHeader from "../../../components/landing/LandingHeader/LandingHeader";
import LandingFooter from "../../../components/landing/LandingFooter/LandingFooter";
import clsx from "clsx";
import AddInfoModal from "../../../components/userCabinet/addInfoModal/addInfoModal";
import {useDocumentTitle} from "../../../hooks/title.hook";
import {useNavigate} from "react-router-dom";
import defaultPicture from "../../../images/defailt_picture.jpg";
import {STORAGE_USER} from "../../../context/Reducers/AuthReducer";



function UserProfilePage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Кабінет користувача — Воби2");
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const {fillUsersInfo, usersInfo, logoutUser} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp()
    const userStorage = JSON.parse(localStorage.getItem(STORAGE_USER));


    const handleLogout = async ()=>{
        await request(`${baseUrl + '/api/logout'}`, 'POST',null,{'Content-Type': "application/json"})
        await logoutUser()
        navigate("/")
    }



    const fetchUserInfo = useCallback(async () => {
        try {

            const data = await request(`${baseUrl}/api/user/${userStorage?.info?.id}`, "GET", null, {'Content-Type': "application/json", authorization: `Bearer ${userStorage?.accessToken}`})

            fillUsersInfo(data[0])
            clearError()

        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        !!userStorage?.info && fetchUserInfo()
    }, [fetchUserInfo])

     const date = new Date(!!usersInfo ? usersInfo.createdAt : null)

    if (loading) {
        return <Preloader/>;
    }
    return (
        <div className={clsx(st.grIndex)}>
            <LandingHeader/>
            <Content className={st.main}>
                <Row gutter={[16, 16]} className={st.card}>
                    <Col span={24} className={clsx(st.title, st.header)}>
                        <Typography.Title>@{usersInfo?.tg_username}</Typography.Title>
                        <div className={st.btnBlock}>
                            <Button onClick={() => setIsModalOpen(true)} type={"primary"}>Змінити Інформацію</Button>
                            <Button onClick={() => {
                                handleLogout()

                            }} type={"primary"}>Вийти з акаунту</Button>
                        </div>
                    </Col>
                    <Col span={24} className={st.title}>
                        <Typography.Text>{"В грі з " + date.getDate() + "." + date.getMonth() + "." + date.getFullYear()}</Typography.Text>
                    </Col>
                    <Col span={8} className={st.cardAvatar}>
                        {( usersInfo?.rank[0]?.images[0] )
                        ? <Image
                            style={{
                                height: 270,
                                width:"100%",
                                borderRadius: 6,
                            }}
                            src={`${baseUrl}${usersInfo?.rank[0]?.images[0]}`}
                        />
                        :  <Image
                            style={{
                                height: 270,
                                width:"100%",
                                borderRadius: 6,
                            }}
                            src={defaultPicture}
                        />
                    }
                    </Col>
                    <Col span={8} className={st.cardInfo}>
                        <div className={st.box}>
                            <Typography.Title>{`Email: ${usersInfo?.email ? usersInfo?.email : "не вказано"}`}</Typography.Title>
                        </div>
                        <div className={st.box}>
                            <Typography.Title>{`Ім’я: ${usersInfo?.name}`}</Typography.Title>
                        </div>
                        <div className={st.box}>
                            <Typography.Title>{`Вік: ${usersInfo?.age}`}</Typography.Title>
                        </div>
                        <div className={st.box}>
                            <Typography.Title>{`Cтать: ${usersInfo?.gender ? usersInfo?.gender : "не вказано"}`}</Typography.Title>
                        </div>
                        <div className={st.box}>
                            <Typography.Title>{`Про мене: ${usersInfo?.about_me ? usersInfo?.about_me : "не вказано"}`}</Typography.Title>
                        </div>

                    </Col>
                    <Col span={8} className={st.cardInfo}>
                        <div className={st.box}>
                            <Typography.Title>{`Сумарно балів: ${usersInfo?.rank_points}`}</Typography.Title>
                        </div>

                        <div className={st.box}>
                            <Typography.Title>{`Доступні бали: ${usersInfo?.shop_points}`}</Typography.Title> {/*виправити*/}
                        </div>
                        <Collapse
                            bordered={false}
                            expandIcon={({isActive}) => <CaretRightOutlined style={{color: "#ff8400", fontSize: "18px"}}
                                                                            rotate={isActive ? 90 : 0}/>}
                            className="site-collapse-custom-collapse"
                        >
                            {usersInfo?.points?.map(el => <Collapse.Panel header={el.lvl_name} key={el._id}
                                                                         className="site-collapse-custom-panel">
                                <p>{el.points} Балів</p>
                            </Collapse.Panel>)}
                        </Collapse>
                    </Col>

                </Row>
            </Content>
            <LandingFooter/>
            {isModalOpen &&
                <AddInfoModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} userStorage={userStorage} initValue={{...usersInfo}}/>}
        </div>
    )
        ;
}

export default UserProfilePage;
