import {actions} from "./actionTypes";

export const usersAdministrationInitialState = {
    usersList: [],
};



export const usersAdministrationReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_USERS_LIST:
            return {
                ...state,
                usersList: [...action.payload]
            };
            case actions.EDIT_USER:
                const updatedUserList = state.usersList.map((item) =>
                    item._id === action.payload._id
                        ? {...action.payload}
                        : item
                );
            return {
                ...state,
                usersList: updatedUserList
            };
        case actions.DELETE_USER:
            const filteredUsers = state.usersList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                usersList: filteredUsers
            };
        case actions.BLOCKED_USER:
            const isBlockedUser = state.usersList.map((item) =>
                item._id === action.payload
                    ? {...item, isBlocked: !item.isBlocked}
                    : item
            );
            return {
                ...state,
                usersList: isBlockedUser
            };
        default:
            return state;
    }
};