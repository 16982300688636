import React, {useCallback, useEffect, useState} from "react"
import {Avatar, Button, Card, Col, FloatButton, Image, List, notification, Row, Space, Typography} from "antd";
import st from "./RanksPage.module.css"
import {Content} from "antd/es/layout/layout";
import {
    AppstoreAddOutlined,
    CommentOutlined,
    DeleteOutlined, EditOutlined,
    MenuOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import GameSettingContext from "../../../context/GameSettingContext";
import {baseUrl} from "../../../config";
import {useHttp} from "../../../hooks/http.hook";
import Preloader from "../../../components/Preloader/Preloader";
import Sidbar from "../../../components/sider/Sider";
import {useDocumentTitle} from "../../../hooks/title.hook";
import CreateRanksModal from "../../../components/Modals/ranksModals/createRanksModal/CreateRanksModal";
import EditRanksModal from "../../../components/Modals/ranksModals/editRanksModal/EditRanksModal";
import DeleteRanksModal from "../../../components/Modals/ranksModals/deleteRanksModal/deleteRanksModal";
import defaultPicture from "../../../images/defailt_picture.jpg"
import clsx from "clsx";


function RanksPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Налаштування Рангів — Воби2");
    const {fillRanksList, ranksList, deleteRank} = React.useContext(GameSettingContext);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState({})
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState({})
    const {loading, error, request, clearError} = useHttp()
    const navigate = useNavigate()


    const fetchRanksList = useCallback(async () => {
        try {
            const data = await request(`${baseUrl}/api/ranks`, "GET", null, {})
            fillRanksList(data)
            clearError()
        } catch (e) {
            notification['error']({
                message: e.message
            });
        }
    }, [request, clearError])

    useEffect(() => {
        fetchRanksList()
    }, [fetchRanksList])


    if (loading) {

        return <Preloader/>
    }

    return (
        <>
            <Sidbar/>
            <Content className={st.gameSetting}>
                <Row className={st.header}>
                    <Col span={20} className={st.title}><Typography.Title>Налаштування Рангів</Typography.Title></Col>
                    <Col span={4} className={st.lvlAddBlock}><span className={st.lvlAdd}
                                                                   onClick={() => setIsModalCreateOpen(true)}>Додати Ранг<AppstoreAddOutlined/></span>
                    </Col>
                </Row>

                <Row className={st.lvlList}>
                    <Col span={24}>
                        <List
                            grid={{
                                gutter: 8,
                                xs: 1,
                                sm: 1,
                                md: 1,
                                lg: 2,
                                xl: 2,
                                xxl: 4
                            }}
                            dataSource={ranksList}
                            renderItem={(item, key, array) => (
                                <List.Item>
                                    <div className={st.cardPosition}>
                                        <div className={st.cardActions}>
                                            <EditOutlined onClick={() => setIsModalEditOpen({
                                                ...item,
                                                bottomRank: key - 1,
                                                topRank: key + 1
                                            })}/>
                                            <DeleteOutlined onClick={() => setIsModalDeleteOpen({
                                                id: item._id,
                                                topRankIndex: key + 1
                                            })}/>
                                        </div>

                                        <Card
                                            hoverable className={clsx(item.reserved && st.rankCard)}>
                                            <div className={st.pictureContainer}>
                                                {item.images[0] && <div  className={st.picture}>
                                                    <span>Карткочка рангу</span>
                                                    <img alt="example"
                                                         src={`${baseUrl}${item.images[0]}`}/>
                                                </div>}
                                                {item.avatar_image && <div className={st.picture}>
                                                    <span>Аватарка рангу</span>
                                                    <img  alt="example"
                                                         src={`${baseUrl}${item.avatar_image}`}/>
                                                </div>}
                                            </div>
                                            {!item.reserved
                                                ? <Card.Meta
                                                    title={`Діапазон рангу від ${item.min} до ${item.max} балів`}/>
                                                : <Card.Meta
                                                    title={`Діапазон зарезервованої області від ${item.min} до ${item.max} балів`}/>}
                                        </Card>

                                    </div>

                                </List.Item>
                            )}
                        />

                    </Col></Row>



                    <FloatButton onClick={() => setIsModalCreateOpen(true)} tooltip={<div>Додати Ранг</div>}
                                 icon={<AppstoreAddOutlined/>}/>

                <CreateRanksModal isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen}/>
                {Object.keys(isModalEditOpen).length > 0 &&
                    <EditRanksModal isModalOpen={isModalEditOpen} setIsModalOpen={setIsModalEditOpen}/>}

                {Object.keys(isModalDeleteOpen).length > 0 &&
                    <DeleteRanksModal isModalOpen={isModalDeleteOpen} setIsModalOpen={setIsModalDeleteOpen}/>}

            </Content>
        </>
    );
}


export default RanksPage;
