import {actions} from "./actionTypes";

export const gameSettingInitialState = {
    questionList: [],
    levelsList: [],
    selectedLevel: null
};



export const gameSettingReducer = (state, action) => {
    switch (action.type) {
        case actions.FILL_LEVELS_LIST:
            return {
                ...state,
                levelsList: [...action.payload]
            };
        case actions.ADD_LEVEL_TO_LIST:
            return {
                ...state,
                levelsList: [...state.levelsList, action.payload]
            };
        case actions.EDIT_LEVEL_TO_LIST:
            const updatedLevelList = state.levelsList.map((item) =>
                item._id === action.payload._id
                    ? {...action.payload}
                    : item
            );
            return {
                ...state,
                levelsList: updatedLevelList
            };

        case actions.DELETE_LEVEL_TO_LIST:
            const filteredLevelItem = state.levelsList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                levelsList: filteredLevelItem
            };
        case actions.SET_SELECTED_LEVEL:

            return {
                ...state,
                selectedLevel: action.payload
            };
        case actions.FILL_QUESTION_LIST:

            return {
                ...state,
                questionList: [...action.payload]
            };
        case actions.CREATE_QUESTION_ITEM:
            return {
                ...state,
                questionList: [
                    ...state.questionList,
                    {...action.payload}
                ]
            };
        case actions.DELETE_QUESTION_ITEM: {
            const filteredQuestionItem = state.questionList.filter(
                (item) => item._id !== action.payload
            );
            return {
                ...state,
                questionList: filteredQuestionItem
            };
        }
        case actions.EDIT_QUESTION_ITEM: {
            const updatedQuestionList = state.questionList.map((item) =>
                item._id === action.payload._id
                    ? {...action.payload}
                    : item
            );
            return {
                ...state,
                questionList: updatedQuestionList
            };
        }
        default:
            return state;
    }
};