import React, {useState} from "react"
import {Button, Col, Form, Input, InputNumber, notification, Row, Skeleton, Space, Typography} from "antd";
import st from "./UserAuthPage.module.css"
import {Content} from "antd/es/layout/layout";
import {useHttp} from "../../../hooks/http.hook";
import {baseUrl} from "../../../config";
import {useDocumentTitle} from "../../../hooks/title.hook";
import RemindPasswordModal from "../../../components/Modals/remindPasswordModal/RemindPasswordModal";
import GameSettingContext from "../../../context/GameSettingContext";


function UserAuthPage() {
    const [document_title, setDoucmentTitle] = useDocumentTitle("Авторизація користувача — Воби2");

    const {loginUser} = React.useContext(GameSettingContext);
    const {loading, error, request, clearError} = useHttp();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onRemindPassword = () => {
        setIsModalOpen(true)
    };


    const onFinish = async (values) => {
        try {
            const body = JSON.stringify({...values})
            const data = await request(
                `${baseUrl}/api/login`,
                "POST",
                body,
                {'Content-Type': "application/json", credentials: 'include'}
            );
            loginUser(data)
            clearError();
        } catch (e) {
            notification["error"]({
                message: e.message
            });
        }
    };


    return (
        <Content className={st.loginBlock}>
            <Row justify={"center"} align={"middle"}>
                <Col span={12} className={st.signInForm}>
                    <Typography.Title>Авторзація користувача</Typography.Title>
                    <Form
                        className={st.card}
                        form={form}
                        onFinish={onFinish}

                        autoComplete="off"
                    >

                        <Form.Item
                            name={"tg_username"}
                            rules={[
                                {
                                    required: true,
                                    message: "Відсутній логін",
                                },
                            ]}
                        >
                            <Input placeholder="Введіть логін"/>
                        </Form.Item>
                        <Form.Item
                            name={"password"}
                            rules={[
                                {
                                    required: true,
                                    message: "Відсутній пароль",
                                },
                            ]}
                        >
                            <Input.Password placeholder="Пароль"
                                            name={"password"}
                                            id={"password"}
                            />
                        </Form.Item>
                        <Form.Item className={st.btnBlock} >
                            <Button disabled={loading} size={"large"} type="primary" htmlType="submit">
                                Зберегти
                            </Button>
                            <Button  disabled={loading} onClick={onRemindPassword} size={"large"}>
                                Забув пароль
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <RemindPasswordModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>
        </Content>
    );
}

export default UserAuthPage;
